
import { DOWNLOAD_INVOICE_FAIL, DOWNLOAD_INVOICE_SUCCESS, GENERATE_ORDER_FAIL, GENERATE_ORDER_SUCCESS, GET_CUSTOMER_SUBSCRIPTION_LIST_FAIL, GET_CUSTOMER_SUBSCRIPTION_LIST_SUCCESS, GET_ORDER_DETAILS_FAIL, GET_ORDER_DETAILS_SUCCESS, GET_SUBSCRIPTION_FAIL, GET_SUBSCRIPTION_SUCCESS, PAYMENT_FAIL, PAYMENT_SUCCESS } from "../Constants/subscriptionConstants";

const initialState = {
    error: null,
    success: null,
    invoice: null,
    subscriptionList: [],
    customerSubscriptionList: [],
    orderDetails: null,
};

export const subscriptionReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_SUBSCRIPTION_SUCCESS:
            return { ...state, error: null, subscriptionList: action.payload };
        case GET_SUBSCRIPTION_FAIL:
            return { ...state, error: action.payload };
        case GET_ORDER_DETAILS_SUCCESS:
            return { ...state, error: null, orderDetails: action.payload };
        case GET_ORDER_DETAILS_FAIL:
            return { ...state, error: action.payload };
        case GET_CUSTOMER_SUBSCRIPTION_LIST_SUCCESS:
            return { ...state, error: null, customerSubscriptionList: action.payload };
        case GET_CUSTOMER_SUBSCRIPTION_LIST_FAIL:
            return { ...state, error: action.payload };
        case GENERATE_ORDER_SUCCESS:
            return { ...state, error: null, success: action.payload };
        case GENERATE_ORDER_FAIL:
            return { ...state, error: action.payload };
        case DOWNLOAD_INVOICE_SUCCESS:
            return { ...state, error: null, success: action.payload };
        case DOWNLOAD_INVOICE_FAIL:
            return { ...state, error: action.payload };
        case PAYMENT_SUCCESS:
            return { ...state, error: null, invoice: action.payload };
        case PAYMENT_FAIL:
            return { ...state, error: action.payload };
        default:
            return state;
    }
};