import React, { useState, useEffect } from "react";
import {
  Card,
  Typography,
  IconButton,
  InputAdornment,
  Button,
  Divider,
  MenuItem,
} from "@mui/material";
import classes from "./auth.module.css";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { CloseEyeIcon, OpenEyeIcon } from "../../Assets/Icons/icons";
import faceBookBtn from "../../Assets/Images/faceBookBtn.png";
import googleBtn from "../../Assets/Images/googleBtn.png";
import {
  getAllGradeIds,
  setStudentPassword,
} from "../../Redux/Actions/userActions";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Alphabetic } from "../../Utilities/commonfunctions";

const StudentRegister = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [password, setPassword] = useState("");
  const [gradeId, setGradeId] = useState("-1");
  const [gradeIdList, setGradeIdList] = useState([]);
  const [showPassword, setShowPassword] = useState(false);
  const userToken = JSON.parse(localStorage.getItem("userToken"));

  const handleSubmit = () => {
    if (gradeId === "-1") {
      alert("Please select Grade Id");
      return;
    } else {
      const userEntry = {
        username: email,
        id: userToken?._id,
        newPassword: password,
        gradeId: gradeId,
        parent_name: name,
      };
      dispatch(setStudentPassword(userEntry, navigate));
    }
  };

  const handleAllGrades = () => {
    dispatch(getAllGradeIds(setGradeIdList));
  };

  useEffect(() => {
    handleAllGrades();
  }, []);

  console.log("gradeIdList =", gradeIdList);
  return (
    <div className={classes.LoginMainDiv}>
      <Card className={classes.LoginMainCard} sx={{ paddingBottom: "0px" }}>
        <Typography variant="h2" className={classes.authHeader}>
          Register Student <br /> Account
        </Typography>
        <ValidatorForm
          useref="form"
          onSubmit={handleSubmit}
          className={`mt-5 mb-4 ${classes.formInputContainer}`}
        >
          <div className="mb-4 mt-4">
            <TextValidator
              id="age"
              select
              fullWidth
              placeholder="Enter Student Name"
              className={`${classes.TextInput1}`}
              value={gradeId}
              size="small"
              focused
              onChange={(e) => {
                setGradeId(e.target.value);
              }}
              classes={{ paper: classes.menuPaper }}
              validators={["required"]} // Add this line
              errorMessages={["Game Name is required"]}
            >
              <MenuItem value={"-1"} sx={{ display: "none" }}>
                <p className={`${classes.selectAge}`}>Select Grade Id</p>
              </MenuItem>
              {Array.isArray(gradeIdList) &&
                gradeIdList.map((datas, index) => {
                  return (
                    <MenuItem value={datas._id}>
                      <p
                        className={`${classes.selectAge}`}
                        style={{ textTransform: "capitalize" }}
                      >
                        {datas.grade?.replaceAll("_", " ")}
                      </p>
                    </MenuItem>
                  );
                })}
            </TextValidator>
          </div>
          <div>
            <TextValidator
              id="nameInput"
              fullWidth
              placeholder="Parent / Guardian's Name"
              className={`${classes.TextInput}`}
              value={name}
              size="small"
              focused
              onChange={(e) => {
                setName(e.target.value);
              }}
              onKeyPress={(e) => {
                Alphabetic(e);
              }}
              validators={["required"]} // Add this line
              errorMessages={["Parent Name is required"]}
            />
          </div>
          <div className={`mt-4`}>
            <TextValidator
              id="emailInput"
              fullWidth
              placeholder="Parent / Guardian's Email"
              className={`${classes.TextInput}`}
              value={email}
              size="small"
              focused
              onChange={(e) => {
                setEmail(e.target.value);
              }}
              validators={["required", "isEmail"]} // Add this line
              errorMessages={[
                "Email Id is required",
                "Please enter valid email id",
              ]}
            />
          </div>
          <div className={`mt-4`}>
            <TextValidator
              id="emailInput"
              fullWidth
              placeholder="Create Password"
              className={`${classes.TextInput}`}
              value={password}
              type={showPassword ? "text" : "password"}
              size="small"
              focused
              onChange={(e) => {
                setPassword(e.target.value);
              }}
              validators={[
                "required",
                "matchRegexp:^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$",
              ]} // Add this line
              errorMessages={["Password is required", "Password is not strong"]}
              InputProps={{
                endAdornment: (
                  <InputAdornment
                    position="end"
                    className={classes.passwordInputAdornment}
                  >
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setShowPassword(!showPassword)}
                      edge="end"
                    >
                      {showPassword ? <CloseEyeIcon /> : <OpenEyeIcon />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </div>

          <div className={`mt-3 ${classes.loginSubmitBtnContainer}`}>
            <Button
              variant="contained"
              className={`${classes.loginSubmitBtn}`}
              type="submit"
              sx={{ marginTop: "10px" }}
            >
              Verify
            </Button>
          </div>
        </ValidatorForm>
      </Card>
    </div>
  );
};

export default StudentRegister;
