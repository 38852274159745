import { api, authApi, getAuthorizationHeader } from "../../Api/api";
import {
  FORGOT_PASSWORD_OTP,
  HEADER_NAME,
  LOGGEDIN_USER,
  PAGE_LOADER,
  SET_STUDENT_FEIEND,
  TOGGLE_SIDEBAR,
  USER_LOGIN_REQUEST_FAIL,
  USER_LOGIN_REQUEST_SUCCESS,
} from "../Constants/userConstants";

export const loginUser =
  (userEntry, navigate, setErrorAlert) => async (dispatch) => {
    try {
      const { data } = await api.post("/super-admin/admin-login", {
        ...userEntry,
      });
      console.log(data?.data.token);
      localStorage.setItem("userToken", JSON.stringify(data?.data));
      dispatch({
        type: USER_LOGIN_REQUEST_SUCCESS,
        payload: data?.data,
      });
      dispatch(pageLoader(false));
      navigate("/home");
    } catch (error) {
      dispatch({
        type: USER_LOGIN_REQUEST_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
      setErrorAlert(true);
      dispatch(pageLoader(false));
    }
  };

export const registerUser = (userEntry, navigate) => async (dispatch) => {
  try {
    const { data } = await api.post(
      "/auth/register-indidual-student",
      userEntry
    );
    console.log(data?.data);
    if (data?.success) {
      dispatch({
        type: FORGOT_PASSWORD_OTP,
        payload: {...data.data,countryCode:userEntry?.country_code},
      });
      // sessionStorage.setItem("otp_data", JSON.stringify(data?.data[0]));
      dispatch(pageLoader(false));
      navigate("/mobileverification", { state: { page: "registerpage" } });
    }
  } catch (error) {
    dispatch({
      type: USER_LOGIN_REQUEST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
    dispatch(pageLoader(false));
  }
};

export const forgotPasswordEmailsend =
  (userEntry, navigate) => async (dispatch) => {
    try {
      const { data } = await api.post("/auth/otp", userEntry);
      console.log(data?.data);
      if (data?.success) {
        dispatch({
          type: FORGOT_PASSWORD_OTP,
          payload: data.data,
        });
        dispatch(pageLoader(false));
      }
    } catch (error) {
      dispatch({
        type: USER_LOGIN_REQUEST_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
      dispatch(pageLoader(false));
    }
  };

export const verifyOtp =
  (userEntry, navigate, route, setPageInfo, setErrMsg) => async (dispatch) => {
    try {
      const { data } = await api.post("/auth/verify-otp", userEntry);
      console.log(data?.data);
      if (data?.success) {
        localStorage.setItem("userToken", JSON.stringify(data?.data));
        sessionStorage.setItem("userToken", data?.data);
        dispatch(pageLoader(false));
        if (setPageInfo) {
          setPageInfo(2);
        } else {
          navigate(route);
        }
      }
    } catch (error) {
      if (setPageInfo && setErrMsg) {
        setErrMsg(
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message
        );
      }
      dispatch({
        type: USER_LOGIN_REQUEST_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
      dispatch(pageLoader(false));
    }
  };

export const setStudentPasswordParent =
  (userEntry, setRegisterSuccess) => async (dispatch) => {
    try {
      const token = JSON.parse(localStorage.getItem("userToken"));
      console.log(token);
      const { data } = await authApi.patch(
        "/auth/set-student-password",
        userEntry,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log(data?.data);
      if (data?.success) {
        localStorage.setItem("userToken", JSON.stringify(data?.data));
        dispatch({
          type: USER_LOGIN_REQUEST_SUCCESS,
          payload: data?.data,
        });
        dispatch(pageLoader(false));
        setRegisterSuccess("Student Added Successfully");
        // navigate("/chooseavatar");
      }
    } catch (error) {
      dispatch({
        type: USER_LOGIN_REQUEST_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
      dispatch(pageLoader(false));
    }
  };

export const setStudentPassword = (userEntry, navigate) => async (dispatch) => {
  try {
    const token = JSON.parse(localStorage.getItem("userToken"));
    console.log(token);
    const { data } = await authApi.patch(
      "/auth/set-student-password",
      userEntry,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    console.log(data?.data);
    if (data?.success) {
      localStorage.setItem("userToken", JSON.stringify(data?.data));
      dispatch({
        type: USER_LOGIN_REQUEST_SUCCESS,
        payload: data?.data,
      });
      dispatch(pageLoader(false));
      navigate("/chooseavatar");
    }
  } catch (error) {
    dispatch({
      type: USER_LOGIN_REQUEST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
    dispatch(pageLoader(false));
  }
};
export const setSelectedAvatar =
  (id, userEntry, navigate, route) => async (dispatch) => {
    try {
      const { data } = await authApi.patch(
        `/student/add-student-avatar/${id}`,
        userEntry,
        {
          headers: {
            Authorization: getAuthorizationHeader(),
          },
        }
      );
      console.log(data?.data);
      if (data?.success) {
        dispatch({
          type: SET_STUDENT_FEIEND,
          payload: data?.data,
        });
        dispatch(pageLoader(false));
        navigate(route);
      }
    } catch (error) {
      dispatch({
        type: USER_LOGIN_REQUEST_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
      dispatch(pageLoader(false));
    }
  };

export const setSelectedFriend =
  (id, userEntry, navigate, selectedItem) => async (dispatch) => {
    try {
      const token = JSON.parse(localStorage.getItem("userToken"));
      const { data } = await authApi.patch(
        `/student/add-student-friend/${id}`,
        userEntry,
        {
          headers: {
            Authorization: getAuthorizationHeader(),
          },
        }
      );
      console.log(data?.data);
      if (data?.success) {
        dispatch({
          type: SET_STUDENT_FEIEND,
          payload: data?.data,
        });
        // dispatch(getUserByToken());
        dispatch(pageLoader(false));
        if (selectedItem === "myprofile") {
        } else {
          navigate("/selectedfriend", { state: { selectedItem } });
        }
      }
    } catch (error) {
      dispatch({
        type: USER_LOGIN_REQUEST_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
      dispatch(pageLoader(false));
    }
  };

export const getUserByToken = (navigate, _id) => async (dispatch) => {
  try {
    let _num = localStorage.getItem("selected_role");
    let _type = 0;
    if (_num && _num === "STUDENT") {
      _type = 0;
    } else if (_num && _num === "PARENT") {
      _type = 1;
    }
    const { data } = await authApi.get(
      `/auth/get-user?role=${_type}&id=${_id}`,
      {
        headers: {
          Authorization: getAuthorizationHeader(),
        },
      }
    );
    // console.log("data", data);
    dispatch(pageLoader(false));
    if (data.success) {
      dispatch({
        type: LOGGEDIN_USER,
        payload: data.data,
      });
    }
  } catch (error) {
    console.log("error", error);
    if (error && error.response && error.response.status === 401) {
      localStorage.removeItem("userToken");
      dispatch(pageLoader(false));
      navigate("/login");
    } else {
      dispatch({
        type: USER_LOGIN_REQUEST_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
      dispatch(pageLoader(false));
    }
  }
};

export const setHeaderName = (name) => async (dispatch) => {
  dispatch({
    type: HEADER_NAME,
    payload: name,
  });
};

export const pageLoader = (value) => async (dispatch) => {
  dispatch({
    type: PAGE_LOADER,
    payload: value,
  });
};

export const setToggleSidebar = (value) => async (dispatch) => {
  dispatch({
    type: TOGGLE_SIDEBAR,
    payload: value,
  });
};

export const getAvatarList = (setAvatarList) => async (dispatch) => {
  try {
    const { data } = await api.get("/avatar/get-all-avatar");
    console.log(data);
    if (data?.success) {
      setAvatarList(data?.data);
    }
  } catch (error) {
    dispatch({
      type: USER_LOGIN_REQUEST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
    dispatch(pageLoader(false));
  }
};

export const getFriendList = (setFriendList) => async (dispatch) => {
  try {
    const { data } = await api.get("/friend/get-all-friend");
    console.log(data);
    if (data?.success) {
      setFriendList(data?.data);
    }
  } catch (error) {
    dispatch({
      type: USER_LOGIN_REQUEST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
    dispatch(pageLoader(false));
  }
};

export const studentLogin = (body, navigate) => async (dispatch) => {
  try {
    const { data } = await api.post("/auth/login", body);
    console.log(data?.data.token);
    localStorage.setItem("userToken", JSON.stringify(data?.data));

    dispatch({
      type: USER_LOGIN_REQUEST_SUCCESS,
      payload: data?.data,
    });
    dispatch(pageLoader(false));
    if (sessionStorage.getItem("myrole") === "PARENT") {
      navigate("/parent-dashboard");
    } else {
      navigate("/home");
    }
  } catch (error) {
    dispatch({
      type: USER_LOGIN_REQUEST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
    sessionStorage.removeItem("myrole");
    dispatch(pageLoader(false));
  }
};

export const forgotPassword = (body, navigate) => async (dispatch) => {
  try {
    const { data } = await api.post("/auth/forgot-password", body);
    console.log(data?.data.token);
    if (data.success) {
      dispatch({
        type: FORGOT_PASSWORD_OTP,
        payload: data.data,
      });
      sessionStorage.setItem("user_email", data?.data?.username);
      dispatch(pageLoader(false));
      navigate("/mobileverification", {
        state: { page: "forgotpasswordpage" },
      });
    }
  } catch (error) {
    dispatch({
      type: USER_LOGIN_REQUEST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
    dispatch(pageLoader(false));
  }
};

export const changePassword = (body, navigate, _token) => async (dispatch) => {
  try {
    const { data } = await api.patch("/auth/reset-password", body, {
      headers: {
        Authorization: `Bearer ${_token}`,
      },
    });
    console.log(data?.data.token);
    if (data.success) {
      sessionStorage.removeItem("user_email");
      sessionStorage.removeItem("userToken");
      dispatch(pageLoader(false));
      navigate("/login");
    }
  } catch (error) {
    dispatch({
      type: USER_LOGIN_REQUEST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
    dispatch(pageLoader(false));
  }
};

export const changePasswordStudent =
  (userEntry, setSuccessAlert, setErrorAlert, setMessage) =>
  async (dispatch) => {
    try {
      const { data } = await authApi.patch(
        "/school/change-password",
        userEntry,
        {
          headers: {
            Authorization: getAuthorizationHeader(),
          },
        }
      );
      dispatch(pageLoader(false));
      if (data.success) {
        //   dispatch({
        //     type: USER_CHANGE_PASSWORD_SUCCESS,
        //     payload: data.message,
        //   });
        setSuccessAlert(true);
        setMessage(data.message);
      }
    } catch (error) {
      console.log("error", error);
      // dispatch({
      //   type: USER_CHANGE_PASSWORD_FAIL,
      //   payload:
      //     error.response && error.response.data.message
      //       ? error.response.data.message
      //       : error.message,
      // });
      setMessage(
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
      );
      setErrorAlert(true);
      dispatch(pageLoader(false));
    }
  };

export const studentGames =
  (userEntry, setAchievements, _token) => async (dispatch) => {
    try {
      const { data } = await api.post("/games/student-games", userEntry, {
        headers: {
          Authorization: `Bearer ${_token}`,
        },
      });
      console.log(data?.data);
      if (data?.success) {
        setAchievements(data.data);
      }
    } catch (error) {
      dispatch({
        type: USER_LOGIN_REQUEST_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
      dispatch(pageLoader(false));
    }
  };

export const gamesProgress =
  (userEntry, setMyAllLevels, _token) => async (dispatch) => {
    try {
      const { data } = await api.post("/games/game-progress", userEntry, {
        headers: {
          Authorization: `Bearer ${_token}`,
        },
      });
      console.log(data?.data);
      if (data?.success) {
        setMyAllLevels(data.data);
      }
    } catch (error) {
      dispatch({
        type: USER_LOGIN_REQUEST_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
      dispatch(pageLoader(false));
    }
  };

export const storeGamesProgress =
  (userEntry, _token, setGameFlag) => async (dispatch) => {
    try {
      const { data } = await api.post("/games/store-game-progress", userEntry, {
        headers: {
          Authorization: `Bearer ${_token}`,
        },
      });
      console.log(data?.data);
      if (data?.success) {
        setGameFlag(true);
      }
    } catch (error) {
      dispatch({
        type: USER_LOGIN_REQUEST_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
      dispatch(pageLoader(false));
    }
  };

export const registerUserParent =
  (userEntry, setPageInfo) => async (dispatch) => {
    try {
      const { data } = await api.post(
        "/auth/register-indidual-student",
        userEntry
      );
      console.log(data?.data);
      if (data?.success) {
        dispatch({
          type: FORGOT_PASSWORD_OTP,
          payload: data.data,
        });
        setPageInfo(1);
      }
    } catch (error) {
      dispatch({
        type: USER_LOGIN_REQUEST_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
      dispatch(pageLoader(false));
    }
  };

export const myCodeCourseProgress =
  (student_id, _token, setMyCodeGames) => async (dispatch) => {
    try {
      const { data } = await api.get(`/games/course-progress/${student_id}`, {
        headers: {
          Authorization: `Bearer ${_token}`,
        },
      });
      console.log(data?.data);
      if (data?.success) {
        setMyCodeGames(data?.data);
      }
    } catch (error) {
      dispatch({
        type: USER_LOGIN_REQUEST_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
      dispatch(pageLoader(false));
    }
  };

export const getAllGradeIds = (setGradeIdList) => async (dispatch) => {
  try {
    const { data } = await api.get(`/class-admin/admin-grades`);
    console.log(data?.data);
    if (data?.success) {
      setGradeIdList(data?.data);
    }
  } catch (error) {
    dispatch({
      type: USER_LOGIN_REQUEST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
    dispatch(pageLoader(false));
  }
};

export const getCouponWithCode =
  (
    coupon_id,
    user_id,
    setApplyCouponChecked,
    applyCouponChecked,
    setCouponInfo
  ) =>
  async (dispatch) => {
    try {
      const { data } = await api.get(`/coupon/${coupon_id}/${user_id}`);
      console.log(data?.data);
      if (data?.success) {
        setApplyCouponChecked(false);
        setCouponInfo(data?.data[0]);
        localStorage.setItem("coupon_info", data?.data[0]?.discount);
      }
    } catch (error) {
      dispatch({
        type: USER_LOGIN_REQUEST_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
      dispatch(pageLoader(false));
    }
  };

export const getLoginUserList =
  (userEntry, setUsersList, setIsPopup) => async (dispatch) => {
    try {
      const { data } = await api.post(`/auth/user_list`, userEntry);
      console.log(data?.data);
      if (data?.success) {
        setUsersList(data?.data);
        setIsPopup(true);
      }
    } catch (error) {
      dispatch({
        type: USER_LOGIN_REQUEST_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
      dispatch(pageLoader(false));
    }
  };

export const getStudentAcheivementsList =
  (student_id, setAchievementsList) => async (dispatch) => {
    try {
      const { data } = await api.get(`/games/get-certificate/${student_id}`, {
        headers: {
          Authorization: getAuthorizationHeader(),
        },
      });
      console.log(data?.data);
      if (data?.success) {
        setAchievementsList(data?.data);
      }
    } catch (error) {
      setAchievementsList([]);
      // dispatch({
      //   type: USER_LOGIN_REQUEST_FAIL,
      //   payload:
      //     error.response && error.response.data.message
      //       ? error.response.data.message
      //       : error.message,
      // });
      dispatch(pageLoader(false));
    }
  };

export const initiatePayment =
  (userEntry, SetInitiatePaySuccess, SetInitiatePayError, navigate) =>
  async (dispatch) => {
    try {
      const { data } = await authApi.post(
        `/customer-subscription/initiate-payment`,
        userEntry,
        {
          headers: {
            Authorization: getAuthorizationHeader(),
          },
        }
      );
      dispatch(pageLoader(false));
      if (data.success) {
        SetInitiatePaySuccess(data?.data);
        localStorage.setItem("secret_id", data?.data?.clientSecret);
        localStorage.setItem("coupon_code", userEntry?.coupon_code);
        navigate("/payment-method");
      }
    } catch (error) {
      SetInitiatePayError(
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
      );
      dispatch(pageLoader(false));
    }
  };

export const downloadCertificate = (selected_cert) => async (dispatch) => {
  try {
    const { data } = await api.get(
      `/games/download-certificate/${selected_cert?.id}`,
      {
        headers: {
          Authorization: getAuthorizationHeader(),
          // 'Content-Type': 'application/pdf'
        },
      }
    );
    const blob = new Blob([data], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });

    console.log(blob);
    const backendUrl = `${process.env.REACT_APP_API_URL}/games/download-certificate/${selected_cert?.id}`;

    // Create a link element
    const a = document.createElement("a");
    a.href = backendUrl;
    a.download = `${selected_cert?.name}-${selected_cert?.date}.pdf`; // Optional: Set the desired file name
    document.body.appendChild(a);

    // Trigger a click event on the link to initiate the download
    a.click();
    if (data?.success) {
      // setAchievementsList(data?.data);
    }
  } catch (error) {
    // setAchievementsList([]);
    // dispatch({
    //   type: USER_LOGIN_REQUEST_FAIL,
    //   payload:
    //     error.response && error.response.data.message
    //       ? error.response.data.message
    //       : error.message,
    // });
    dispatch(pageLoader(false));
  }
};

export const getAllNotifications =
  (user_id, setMyAllNotifications, student_id) => async (dispatch) => {
    try {
      const { data } = await api.get(
        `/notification/get-notifications/${user_id}?student_id=${student_id ? student_id : ""}&page=1&limit=10`,
        {
          headers: {
            Authorization: getAuthorizationHeader(),
          },
        }
      );
      console.log(data?.data);
      if (data?.success) {
        let a = [
          ...data?.data?.new,
          ...data?.data?.lastweek,
          ...data?.data?.lastmonth,
          ...data?.data?.older,
        ];
        // if (a?.length > 0) {
        //   setMyAllNotifications([data?.data]);
        // } else {
        //   setMyAllNotifications([]);
        // }
        setMyAllNotifications(a)
      }
    } catch (error) {
      setMyAllNotifications([]);
      // dispatch({
      //   type: USER_LOGIN_REQUEST_FAIL,
      //   payload:
      //     error.response && error.response.data.message
      //       ? error.response.data.message
      //       : error.message,
      // });
      dispatch(pageLoader(false));
    }
  };

export const deleteVerifyUser = (phone, navigate) => async (dispatch) => {
  try {
    const { data } = await api.post(`/auth/delete-student/${phone}`, {
      headers: {
        Authorization: getAuthorizationHeader(),
      },
    });
    console.log(data?.data);
    if (data?.success) {
      navigate("/register", { state: { details: "edit" } });
    }
  } catch (error) {
    // dispatch({
    //   type: USER_LOGIN_REQUEST_FAIL,
    //   payload:
    //     error.response && error.response.data.message
    //       ? error.response.data.message
    //       : error.message,
    // });
    dispatch(pageLoader(false));
  }
};

export const updateNotificationStatus = (_id,handleGetNotifications) => async (dispatch) => {
  try {
    let userEntry  = {seen:true};
    const { data } = await api.delete(`/notification/delete-all-user-notification/${_id}`, {
      headers: {
        Authorization: getAuthorizationHeader(),
      },
    });
    console.log(data?.data);
    if (data?.success) {
      handleGetNotifications();
      // navigate("/register", { state: { details: "edit" } });
    }
  } catch (error) {
    // dispatch({
    //   type: USER_LOGIN_REQUEST_FAIL,
    //   payload:
    //     error.response && error.response.data.message
    //       ? error.response.data.message
    //       : error.message,
    // });
    dispatch(pageLoader(false));
  }
};