import Navbar from "../../Layout/Navbar";
import { Box, Container, Grid, Typography, Button } from "@mui/material";
import "./profile.css";
import classes from "./dashboard.module.css";
import Elephant from "../../Assets/Images/elephant.png";
import Elephant2 from "../../Assets/Images/elephant2.png";
import Elephant3 from "../../Assets/Images/elephant3.png";
import WelcomeImg from "../../Assets/Images/welcome-img.png";
import Img1 from "../../Assets/Images/dashboard-rocket.png";
import Img2 from "../../Assets/Images/dashboard-butterfly.png";
import ProfileLogo from "../../Assets/Images/profile-logo.png";
import Star from "../../Assets/Images/star.png";
import Diamond from "../../Assets/Images/diamond.png";
import Topic1 from "../../Assets/Images/topic1.png";
import Topic2 from "../../Assets/Images/topic2.png";
import Topic3 from "../../Assets/Images/topic3.png";
import Topic4 from "../../Assets/Images/topic4.png";
import Topic5 from "../../Assets/Images/topic5.png";
import Topic6 from "../../Assets/Images/topic6.png";
import { useEffect, useRef, useState } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import GameA from "../../Assets/Images/game-a.png";
import GameBallon from "../../Assets/Images/game-ballon.png";
import GameButterfly from "../../Assets/Images/game-butterfly.png";
import GameCloud from "../../Assets/Images/game-cloud.png";
import GameRocket from "../../Assets/Images/game-rocket.png";
import BottomContent from "./BottomContent";
import { Link, useNavigate } from "react-router-dom";
import SelectUserPopup from "../popup/SelectUserPopup";
import { studentGames } from "../../Redux/Actions/userActions";
import { useDispatch, useSelector } from "react-redux";
import { splitFirstLetter } from "../../Utilities/commonfunctions";
const allTopics = [
  {
    id: 1,
    img: Topic1,
    content: "Computer Programming",
  },
  {
    id: 2,
    img: Topic2,
    content: "Algorithms",
  },
  {
    id: 3,
    img: Topic3,
    content: "Digital Literacy",
  },
  {
    id: 4,
    img: Topic4,
    content: "Safety",
  },
  {
    id: 5,
    img: Topic5,
    content: "Computer Programming-2",
  },
  {
    id: 6,
    img: Topic6,
    content: "Information Technology",
  },
];

const dummyAch = [
  {
    id: 1,
    img: Elephant,
  },
  {
    id: 2,
    img: Elephant2,
  },
  {
    id: 3,
    img: Elephant3,
  },
];

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 3,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1024, min: 850 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 850, min: 200 },
    items: 1,
  },
};

function Dashboard() {
  const [successPop, setSuccessPop] = useState(true);
  const [value, setValue] = useState("1");
  const [myTopic, setMyTopic] = useState("");
  const [achievements, setAchievements] = useState([]);
  const { userInfo, userRole, error, loggedUser } = useSelector(
    (state) => state.userLogin
  );
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const iframeRef = useRef(null);
  let _token = JSON.parse(localStorage.getItem("userToken"));
  let subscription_status = userInfo?.customerSubscriptionCount > 0;

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  console.log("myTopic =", myTopic);
  function handleStudentGames() {
    const body = {
      // studentId: userInfo?.studentInfo?._id || _token?.data[0]?._id,
      studentId:
        userInfo?.studentInfo?._id ||
        _token?.data[0]?._id ||
        _token?.data?.student?._id,
      topic: myTopic.replace(" ", "_"),
    };
    dispatch(studentGames(body, setAchievements, _token?.accessToken));
  }

  const handleClose = () => {
    setSuccessPop(false);
  };

  const handleCheckUser = () => {
    if (subscription_status) {
      navigate("/inventory");
    } else if (!subscription_status && _token?.data[0]?.gst_type === "B2C") {
      navigate("/select-subscription");
    } else if (!subscription_status && _token?.data[0]?.gst_type === "B2B") {
      navigate("/payment-details");
    }
  };

  const handleGameOneClicked = () => {
    let body = {
      level_id: "66771d02a1e71d7015511a0d",
    };
    navigate("/gamepage", { state: { iframe_infos: body } });
  };

  const handleGameTwoClicked = () => {
    let body = {
      level_id: "66771ee1a1e71d7015511b0a",
    };
    navigate("/gamepage", { state: { iframe_infos: body } });
  };

  console.log("subscription_status =",subscription_status)
  console.log("achievements =",achievements)

  useEffect(() => {
    if (subscription_status && myTopic?.length > 0) {
      handleStudentGames();
    }
  }, [myTopic]);

  useEffect(() => {
    if (subscription_status) {
      setMyTopic("computer programming");
    } else {
      setMyTopic("");
    }
  }, []);

  console.log("userInfo =", userInfo);
  return (
    <>
      <Box className="dashboard-home">
        <Container>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={3}>
              <Box className="left-box" mb={3}>
                <Typography>
                  Picture <br /> Puzzles
                </Typography>
                <img src={Elephant} alt="elephant" />
                <Box textAlign={"center"}>
                  {/* <Link to={"/levels"}> */}
                  <Button onClick={handleGameOneClicked}>Play</Button>
                  {/* </Link> */}
                </Box>
              </Box>
              <Box className="left-box">
                <Typography>
                  Sequencing <br /> Puzzles
                </Typography>
                <img src={Elephant2} alt="elephant" />
                <Box textAlign={"center"}>
                  {/* <Link to={"/levels"}> */}
                  <Button onClick={handleGameTwoClicked}>Play</Button>
                  {/* </Link> */}
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <Box className="center-box">
                <Grid container>
                  <Grid item xs={12} sm={12} md={7}>
                    <Typography fontSize={{xs:"22px",sm:"22px",md:"24px"}}>
                      Hi {userInfo?.studentInfo?.first_name}{" "}
                      {userInfo?.studentInfo?.last_name} !
                    </Typography>
                    <Typography fontSize={{xs:"31px",sm:"42px",md:"60px"}} lineHeight={"normal"}>
                      Welcome to Metaverse
                    </Typography>
                    <Typography fontSize={"20px"}>
                      {" "}
                      Coding App, Select Game..
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={12} md={5}>
                    <Box textAlign={{xs:"start",sm:"start",md:"end"}}>
                      <Link to={"/myprofile"}>
                        <Button>My Account</Button>
                      </Link>
                    </Box>
                  </Grid>
                </Grid>
                <img
                  src={WelcomeImg}
                  alt="welcome-img"
                  className="welcome-img"
                />
                <img src={Img1} alt="welcome-img" className="img-one" />
                <img src={Img2} alt="welcome-img" className="img-two" />
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={3}>
              <Box
                className="right-box-one"
                mb={3}
                // onClick={() => navigate("/progress")}
              >
                <Typography fontSize={"24px"}>My Progress</Typography>
                <Box
                  display={"flex"}
                  alignItems={"end"}
                  justifyContent={"space-between"}
                >
                  {userInfo?.avatar_url ? (
                    <img
                      src={`https://api.meta.apps.org.in/api/${userInfo?.avatar_url}`}
                      className={classes.avatar_url}
                      style={{ width: "50px", border: "5px solid #854dff" }}
                      alt="elephant"
                    />
                  ) : (
                    <span className={classes.split_letters_two}>
                      {splitFirstLetter(
                        `${userInfo?.studentInfo?.first_name} ${userInfo?.studentInfo?.last_name}`
                      )}
                    </span>
                  )}

                  <Box
                    display={"flex"}
                    flexDirection={"column"}
                    gap={"10px"}
                    alignItems={"end"}
                    justifyContent={"space-between"}
                  >
                    <img src={Star} alt="elephant" />
                    <Typography>
                      Level {userInfo?.studentInfo?.level}
                    </Typography>
                  </Box>
                </Box>
                <Box
                  height={"12px"}
                  bgcolor={"#557CCB"}
                  borderRadius={"20px"}
                  mt={1}
                >
                  <Typography
                    height={"100%"}
                    width={`${userInfo?.progress}%`}
                    bgcolor={"#FFCB00"}
                    borderRadius={"20px"}
                  ></Typography>
                </Box>
                <Typography fontSize={"15px"} mt={3}>
                  {userInfo?.progress}% Progress
                </Typography>
              </Box>
              <Box className="right-box-two">
                <Box>
                  <Typography fontSize={"24px"}>Diamonds</Typography>
                  <Typography fontSize={"40px"}>
                    {userInfo?.studentInfo?.diamonds}
                  </Typography>
                </Box>
                <img src={Diamond} alt="elephant" />
                <Box>
                  {/* <Button onClick={handleCheckUser}>Buy Gifts</Button> */}
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Container>
        <Box className="all-topics-wrapper">
          <Typography
            color={"#fff"}
            fontWeight={"600"}
            textAlign={"center"}
            fontSize={"30px"}
          >
            All Topics
          </Typography>
          <Box
            className="all-topics"
            sx={{ pointerEvents: subscription_status ? "" : "none" }}
          >
            {allTopics.map((data) => {
              return (
                <Box
                  key={data.id}
                  textAlign={"center"}
                  sx={{
                    cursor: "pointer",
                    opacity: data?.content.toLowerCase() === myTopic ? 1 : 0.5,
                  }}
                  onClick={() => setMyTopic(data?.content.toLowerCase())}
                >
                  <img src={data.img} alt="topic" />
                  <Typography width={"95%"} color={"#fff"} fontWeight={"600"}>
                    {data.content}{" "}
                  </Typography>
                </Box>
              );
            })}
          </Box>
        </Box>
        <Box className="algo-games">
          <Container>
            {subscription_status && (
              <>
                <Typography
                  className="mycode-title"
                  textTransform={"capitalize"}
                >
                  {myTopic ?? "Algorithms Games"}
                </Typography>
                <Box className="achievments-slider">
                  {achievements?.length > 0 ? (
                    <Carousel
                      responsive={responsive}
                      autoPlaySpeed={1500}
                      infinite={true}
                      arrows={true}
                    >
                      {Array.isArray(achievements) &&
                        achievements?.map((item, index) => {
                          return (
                            <Box
                              className="achievement-box"
                              width={"85%"}
                              margin={"auto"}
                            >
                              <Typography className="achievement-title">
                                {item.game_name}
                              </Typography>
                              <Typography className="achievement-sub-title">
                                Total Diamond : <b>{item.totalDiamonds}</b>
                              </Typography>
                              <Typography className="achievement-sub-title">
                                No. of Level : <b>{item?.totalLevels}</b>
                              </Typography>
                              <Button
                                className="btn-one mt-4"
                                onClick={() =>
                                  navigate("/levels", {
                                    state: { singleGameDetail: item },
                                  })
                                }
                                style={{ textTransform: "capitalize" }}
                              >
                                {item?.status}
                              </Button>
                              <img
                                src={`${process.env.REACT_APP_API_URL}/${item.cover_image}`}
                                alt="elephant"
                                width={"120px"}
                              />
                            </Box>
                          );
                        })}
                    </Carousel>
                  ) : (
                    <Typography
                      className="achievement-title"
                      fontSize={"24px"}
                      fontWeight={"600"}
                      pl={5}
                    >
                      No Games Found
                    </Typography>
                  )}
                </Box>
                <img src={GameA} alt="games" className="game-a" />
                <img src={GameBallon} alt="games" className="game-ballon" />
                <img src={GameCloud} alt="games" className="game-cloud" />
                <img src={GameRocket} alt="games" className="game-rocket" />
                <img
                  src={GameButterfly}
                  alt="games"
                  className="game-butterfly"
                />
              </>
            )}
            <BottomContent />
          </Container>
        </Box>
      </Box>
      {/* <Box height={"400px"}>
      <iframe ref={iframeRef} src="http://localhost:3001/homepage" width={"100%"} allowFullScreen={true} height={"100%"}></iframe>
      </Box> */}
    </>
  );
}

export default Dashboard;
