import React, { useState, useEffect } from "react";
import {
  Card,
  Typography,
  IconButton,
  InputAdornment,
  Button,
  Divider,
} from "@mui/material";
import classes from "./auth.module.css";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { CloseEyeIcon, OpenEyeIcon } from "../../Assets/Icons/icons";
import faceBookBtn from "../../Assets/Images/faceBookBtn.png";
import googleBtn from "../../Assets/Images/googleBtn.png";
import { useDispatch } from "react-redux";
import { changePassword } from "../../Redux/Actions/userActions";
import { useNavigate } from "react-router-dom";
import { USER_LOGIN_REQUEST_FAIL } from "../../Redux/Constants/userConstants";

const NewPassword = () => {
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();


  const handleSubmit = () => {
    if (newPassword !== confirmPassword) {
      dispatch({
        type: USER_LOGIN_REQUEST_FAIL,
        payload:"Confirm Password doesn't match",
    });
    } else {
      let _token = sessionStorage.getItem("userToken");
      let body ={
        username:sessionStorage.getItem("user_email"),
        newPassword:newPassword
      };
      dispatch(changePassword(body,navigate,_token));
    }


  };
  return (
    <div className={classes.LoginMainDiv}>
      <Card className={classes.LoginMainCard} sx={{paddingBottom:"0px"}} >
        <Typography variant="h2" className={classes.authHeader}>
         Forgot Password
        </Typography>
        <ValidatorForm
          useref="form"
          onSubmit={handleSubmit}
          className={`mt-5 mb-4 ${classes.formInputContainer}`}
        >
           <div className={`mt-4`}>
            <TextValidator
              id="emailInput"
              fullWidth
              placeholder="New Password"
              className={`${classes.TextInput}`}
              value={newPassword}
              type={showPassword ? "text" : "password"}
              size="small"
              focused
              onChange={(e) => {
                setNewPassword(e.target.value);
              }}
              validators={[
                "required",
                "matchRegexp:^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$",
              ]} // Add this line
              errorMessages={["New Password is required","Password is not strong"]}
              InputProps={{
                endAdornment: (
                  <InputAdornment
                    position="end"
                    className={classes.passwordInputAdornment}
                  >
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setShowPassword(!showPassword)}
                      edge="end"
                    >
                      {showPassword ? <CloseEyeIcon /> : <OpenEyeIcon />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </div>
          <div className={`mt-4`}>
            <TextValidator
              id="emailInput"
              fullWidth
              placeholder="Confirm Password"
              className={`${classes.TextInput}`}
              value={confirmPassword}
              type={showPassword ? "text" : "password"}
              size="small"
              focused
              onChange={(e) => {
                setConfirmPassword(e.target.value);
              }}
              validators={[
                "required"
              ]} // Add this line
              errorMessages={["Confirm Password is required"]}
              InputProps={{
                endAdornment: (
                  <InputAdornment
                    position="end"
                    className={classes.passwordInputAdornment}
                  >
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setShowPassword(!showPassword)}
                      edge="end"
                    >
                      {showPassword ? <CloseEyeIcon /> : <OpenEyeIcon />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </div>
          <div className={`mt-3 ${classes.loginSubmitBtnContainer}`}>
            <Button
              variant="contained"
              className={`${classes.loginSubmitBtn}`}
              type="submit"
              sx={{marginTop:"10px"}} 
            >
              Submit
            </Button>
          </div>
        </ValidatorForm>
      </Card>
    </div>
  );
};

export default NewPassword;
