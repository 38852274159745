import React, { useEffect, useRef, useState } from "react";
import { Card, Typography, Button, MenuItem, Box } from "@mui/material";
import classes from "./auth.module.css";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { useLocation, useNavigate } from "react-router-dom";
import { Numeric, Alphabetic } from "../../Utilities/commonfunctions";
import { useDispatch } from "react-redux";
import { pageLoader, registerUser } from "../../Redux/Actions/userActions";
import IntlTelInput from "react-intl-tel-input";
import 'react-intl-tel-input/dist/main.css';

const Register = () => {
  const [inputs, setInputs] = useState({ age: "-1", name: "", username: "",country_code:"1",country_id:"us" });
  const [errorAlert, setErrorAlert] = useState(false);
  const [isValid, setIsValid] = useState(true);
  const [countryCode, setCountryCode] = useState("1");
  const [selectedCountry, setSelectedCountry] = useState("us");
  const [isFlagSelected, setIsFlagSelected] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const containerRef = useRef(null);

  const handleSubmit = () => {
    // if (inputs?.username?.length != 10) {
    //   setIsValid(false);
    // } else 
    if (!isValid) {
      setIsValid(false);
    }
    else if (inputs.age === "-1") {
      alert("Please select age");
    } 
    else {
      sessionStorage.setItem("student_info", JSON.stringify(inputs));
      dispatch(pageLoader(true));
      dispatch(registerUser(inputs, navigate, setErrorAlert));
    }
  };

  const handleFlagChange = (currentNumber, countryData, fullNumber, isValid) =>{
    setIsFlagSelected(false);
    setSelectedCountry(countryData.iso2);
    if (location?.state?.details === "edit") {
      setInputs({
        ...inputs,
        country_id:countryData?.iso2
      });
    } else {
      setInputs({
        ...inputs,
        country_id:countryData?.iso2,
        username:""
      });
    }

    // setTimeout(()=>{
    //   setIsFlagSelected(false);
    // },1000)
  }

  const handlePhoneNumberChange = (isValidNum, value, country) => {
    setIsValid(isValidNum);
    setCountryCode(country?.dialCode);
    setSelectedCountry(country?.iso2);
    setInputs({
      ...inputs,
      username: value,
      country_code: country?.dialCode,
      country_id: country?.iso2,
    });
  };

  const handleClickOutside = (event) => {
    console.log("sss" , event)
    console.log("containerRef.current " , containerRef)
    if (containerRef.current && !containerRef.current.contains(event.target)) {
      setIsFlagSelected(false);
    }
  };

  useEffect(() => {
    if (location?.state?.details === "edit") {
      let existing_info = JSON.parse(sessionStorage.getItem("student_info"));
      setInputs({
        ...inputs,
        age: existing_info?.age,
        name: existing_info?.name,
        username: existing_info?.username,
        country_code: existing_info?.country_code,
        country_id: existing_info?.country_id,
      });
    }
  }, []);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
  console.log("isFlagSelected =",isFlagSelected)
  return (
    <div className={classes.LoginMainDiv}>
      <Card className={classes.LoginMainCard} sx={{ paddingBottom: "15px",overflow:"visible" }}>
        <Typography variant="h2" className={classes.authHeader}>
          Register Student Account
        </Typography>
        <ValidatorForm
          useref="form"
          onSubmit={handleSubmit}
          className={`mt-5 mb-4 ${classes.formInputContainer}`}
        >
          <div className="mb-4">
            <TextValidator
              id="age"
              select
              fullWidth
              placeholder="Enter Student Name"
              className={`${classes.TextInput1}`}
              value={inputs.age}
              size="small"
              focused
              onChange={(e) => {
                setInputs({ ...inputs, age: e.target.value });
              }}
              classes={{ paper: classes.menuPaper }}
              validators={["required"]} // Add this line
              errorMessages={["Game Name is required"]}
            >
              <MenuItem value={"-1"} sx={{ display: "none" }}>
                <p className={`${classes.selectAge}`}>Select Age</p>
              </MenuItem>
              {["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "10+"].map(
                (item, index) => {
                  return (
                    <MenuItem value={item}>
                      <p className={`${classes.selectAge}`}>{item}</p>
                    </MenuItem>
                  );
                }
              )}
            </TextValidator>
          </div>
          <div>
            <TextValidator
              id="studentname"
              fullWidth
              placeholder="Enter Student Name"
              className={`${classes.TextInput}`}
              value={inputs.name}
              size="small"
              focused
              onChange={(e) => {
                setInputs({ ...inputs, name: e.target.value });
              }}
              onKeyPress={(e) => {
                Alphabetic(e);
              }}
              validators={["required"]} // Add this line
              errorMessages={["Student Name is required"]}
            />
          </div>
          {/* <div className={`mt-4`}>
            <TextValidator
              id="studentNumber"
              fullWidth
              placeholder="Enter Parents / Guardian's Mobile Number"
              className={`${classes.TextInput}`}
              value={inputs.username}
              type={"text"}
              size="small"
              focused
              onChange={(e) => {
                setInputs({ ...inputs, username: e.target.value });
              }}
              onKeyPress={(e) => {
                Numeric(e);
              }}
              inputProps={{ maxLength: 10 }}
              validators={["required"]} // Add this line
              errorMessages={["Mobile Number is required"]}
            />
          </div> */}
          <div className={`${classes.new_countrycode_field} mt-4`} ref={containerRef}  >
                    <IntlTelInput
                      containerClassName="intl-tel-input"
                      inputClassName="form-control custom-placeholder"
                      fieldName="phone"
                      onPhoneNumberChange={handlePhoneNumberChange}
                      onSelectFlag={handleFlagChange}
                      onFlagClick={()=>setIsFlagSelected(true)}
                      value={inputs.username}
                      defaultCountry={inputs.country_id}
                    />
                    {!isValid && <p className={classes.err_text}>Enter Valid number</p>}
                  </div>
         <Box mb={1} visibility={isFlagSelected ? "hidden":"visible"} className={`mt-3 ${classes.loginSubmitBtnContainer}`}>
            <Button
              variant="contained"
              className={`${classes.loginSubmitBtn}`}
              type="submit"
              sx={{ marginTop: "12px" }}
            >
              Get Secure Code
            </Button>
          </Box>
        </ValidatorForm>
      </Card>
      <Button
        className={classes.footerBtn}
        variant="contained"
        onClick={() => navigate("/login")}
      >
        Already have an account ?
      </Button>
    </div>
  );
};

export default Register;
