import { CLOSE_ALERT, FORGOT_PASSWORD_OTP, LOGGEDIN_USER, PAGE_LOADER, SET_STUDENT_FEIEND, USER_LOGIN_REQUEST_FAIL, USER_LOGIN_REQUEST_SUCCESS } from "../Constants/userConstants";

const initialState = {
    loading: false,
    loggedUser: null,
    userInfo: null,
    errorMessage: null,
    errorAlert: false,
    success: null,
    headerName: "home",
    toggleSideBar: false,
    otp_data: null,
}

export const userReducers = (state = initialState, action) => {
    switch (action.type) {
        case PAGE_LOADER:
            return { ...state, loading: action.payload };
        case USER_LOGIN_REQUEST_SUCCESS:
            return { ...state, loggedUser: action.payload, error: null };
        case USER_LOGIN_REQUEST_FAIL:
            return { ...state, errorMessage: action.payload, errorAlert: true };
        case CLOSE_ALERT:
            return { ...state, errorAlert: false, errorMessage: null };
        case FORGOT_PASSWORD_OTP:
            return { ...state, otp_data: action.payload };
        case SET_STUDENT_FEIEND:
            return { ...state, success: action.payload };
        case LOGGEDIN_USER:
            return { ...state, userInfo: action.payload };
        default:
            return state;
    }
}