import { Box, Button, Container, Grid, Typography } from "@mui/material";
import classes from './myprofile.module.css'
import { DownloadIcon, ViloetBackArrow } from "../../Assets/Icons/icons";
import Achievements from "../../Assets/Images/achievements.png";
import AchievmentOne from "../../Assets/Images/achievment-one.png";
import AchievmentTwo from "../../Assets/Images/achievment-two.png";
import BottomImg from "../../Assets/Images/bottom-img.png";
import { useState,useEffect } from "react";
import '../dashboard/profile.css'
import {Link, useLocation, useNavigate} from 'react-router-dom';
import dayjs from "dayjs";
import { downloadCertificate } from "../../Redux/Actions/userActions";
import { useDispatch } from "react-redux";

const dummyAchievments = [
    {
        id: 1,
        img: AchievmentOne,
        title: "Computer Programming 2",
        sub_title: "Acheived On Jan 2022"
    },
    {
        id: 2,
        img: AchievmentTwo,
        title: "Information Technology",
        sub_title: "Acheived On Jan 2022"
    },
    {
        id: 3,
        img: AchievmentOne,
        title: "Algorithm",
        sub_title: "Acheived On Jan 2022"
    },
    {
        id: 4,
        img: AchievmentTwo,
        title: "Computer Programming 2",
        sub_title: "Acheived On Jan 2022"
    },
]

function MyAchievments() {
    const [achievments, setAchievements] = useState(dummyAchievments);
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const my_acheivements = location?.state?.achievementsList;

    function handleCertificate(cert) {
        let selected_cert = {
            id:cert?._id,
            name:cert?.topic?.replace("_"," "),
            date: dayjs(cert?.createdAt).format("MMMM YYYY"),
        }
        dispatch(downloadCertificate(selected_cert))
    }

    function handleAchImg(index) {
        if (index % 2 == 0) {
          return AchievmentOne;
        }
        else{
          return AchievmentTwo;
        }
      }

    useEffect(() => {
      window.scroll({
        top:0,
        behavior:"smooth"
      })
    
    }, [])
    
    return (<>
        <Container sx={{ paddingBottom: "15px" }} >
            <Typography className={classes.main_title} >
                <span style={{cursor:"pointer"}} onClick={()=>navigate(-1)}>
                <ViloetBackArrow />
                </span>
                  My Achievments
            </Typography>
            <Grid container>
                <Grid item xs={12} sm={12} md={5}>
                    <Box width={{xs:"80%",sm:"90%",md:"100%"}} margin={"auto"}>
                        <img src={Achievements} alt="achievment" width={"100%"} />
                    </Box>
                </Grid>
                <Grid item xs={1} sm={1} md={1}></Grid>
                <Grid item xs={12} sm={12} md={6}>
                    <Box className={classes.myachievment_wrapper}>
                        {Array.isArray(my_acheivements) &&
                        my_acheivements?.map((values, i) => {
                            return <Box key={i} display={"flex"} justifyContent={"space-between"} alignItems={"center"} >
                                <Box className={classes.single_achiev}>
                                    <img src={handleAchImg(i)} alt="achievments" />
                                    <Box  >
                                    <Typography>{values.topic?.replace("_"," ")}</Typography>
                                    <span>Achieved On {dayjs(values.createdAt).format("MMMM YYYY")}</span>
                                    </Box>
                                </Box>
                                <Typography className={classes.bottom_title} onClick={()=>handleCertificate(values)}>
                                    <DownloadIcon /> <span className={classes.download_now}>Download Now</span>
                                </Typography>
                            </Box>
                        })}
                    </Box>
                </Grid>
            </Grid>
            <Box className="bottom-content" >
                <img src={BottomImg} alt="games" />
                <Typography ml={3} >
                    <b>Hurray !</b> <br />
                    You will get certificates for your achievements
                </Typography>
            </Box>
        </Container>
    </>)
}

export default MyAchievments;