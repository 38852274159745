import React, { useState, useEffect } from "react";
import { Card, Typography, Button } from "@mui/material";
import classes from "./auth.module.css";
import OtpInput from "react-otp-input";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { Editicon } from "../../Assets/Icons/icons";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  deleteVerifyUser,
  forgotPasswordEmailsend,
  pageLoader,
  verifyOtp,
} from "../../Redux/Actions/userActions";
import AlertBox from "../../Components/AlertBox";
import { useLocation, useNavigate } from "react-router-dom";

const MobileVerification = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const [otp, setOtp] = useState("");
  const { otp_data } = useSelector((state) => state.userLogin);
  const [time, setTime] = useState(5);
  const [disable, setDisable] = useState({
    name: `Resend in ${time}s`,
    state: true,
  });
  console.log("otp_data =",otp_data)
  useEffect(() => {
    const timer = setInterval(() => {
      setTime((prevCount) => prevCount - 1);
      setDisable({
        name: `Resend ${time - 1}s`,
        state: true,
      });
    }, 1000);

    if (time === 0) {
      clearInterval(timer);
      setDisable({
        name: "Resend Otp",
        state: false,
      });
    }
    return () => {
      clearInterval(timer);
    };
  }, [time]);

  const handleEmailSubmit = (e) => {
    let title;
    if (
      /\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}\b/.test(
        otp_data.username
      )
    ) {
      title = "email";
      // setTitle(title);
    } else {
      title = "phone";
      // setTitle(title);
    }
    const userEntry = {
      username: otp_data.username,
      title: title,
    };
    // console.log("this is what user entry look like", userEntry);
    dispatch(pageLoader(true));
    dispatch(forgotPasswordEmailsend(userEntry));
  };

  const handleEdit = ()=>{
    
    if (location?.state.page === "registerpage") {
      dispatch(deleteVerifyUser(otp_data.username , navigate))
      
    } else {
      navigate("/forgotpassword",{state:{details:otp_data?.username}});
    }
  }

  const handleSubmit = () => {
    const userEntry = {
      username: otp_data.username,
      otp: otp,
      title: "parent",
    };
    dispatch(pageLoader(true));
    let route = "";
    if (location?.state.page === "registerpage") {
      route = "/studentregister"
    } else {
      route = "/newpassword"
      userEntry.title = "forgot-password"
    }
    sessionStorage.removeItem("student_info");
    dispatch(verifyOtp(userEntry, navigate, route));
  };
  return (
    <div className={classes.LoginMainDiv}>
      <Card className={classes.LoginMainCard}>
        <Typography variant="h2" className={classes.authHeader}>
          Verify Secure Code
        </Typography>
        <Typography
          variant="h6"
          className={`mt-2 ${classes.authHeaderSubText}`}
        >
          Please enter 4 digit Secure code we have sent you in your <br />
          <span
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {" "}
            {
                /\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+.[A-Za-z]{2,}\b/.test(otp_data?.username) ? "Email ID" : "Mobile Number"
              }
            <b style={{ marginLeft: "6px" }}>
              {
                /\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+.[A-Za-z]{2,}\b/.test(otp_data?.username) ? "" : `+${otp_data?.countryCode} `
              }
              {otp_data?.username} <div className={classes.edit_icon} onClick={handleEdit}><Editicon /></div>
            </b>{" "}
          </span>
        </Typography>
        <Typography className={`${classes.authHeaderSubText} mb-5`}>
          Otp:{otp_data?.otp}
        </Typography>
        <ValidatorForm
          useref="form"
          onSubmit={handleSubmit}
          className={`mt-5 mb-4 ${classes.formInputContainer}`}
        >
          <div className={`${classes.otpWrapper}`}>
            <OtpInput
              value={otp}
              onChange={setOtp}
              numInputs={4}
              containerStyle={classes.otpInputContainer}
              inputStyle={classes.otpInput}
              renderSeparator={<span> </span>}
              renderInput={(props) => <input {...props} />}
            />
          </div>
          <Typography
            variant="h6"
            className={`mt-4  ${classes.authHeaderSubText}`}
          >
            Didn't Receive OTP ?
            <b onClick={handleEmailSubmit} style={{ cursor: "pointer" }}>
              {" "}
              {disable.name}
            </b>
          </Typography>
          <div className={`mt-3 ${classes.loginSubmitBtnContainer}`}>
            <Button
              variant="contained"
              className={`${classes.loginSubmitBtn}`}
              type="submit"
            >
              Verify
            </Button>
          </div>
        </ValidatorForm>
      </Card>
    </div>
  );
};

export default MobileVerification;
