import { initializeApp } from "firebase/app";
import {
  FacebookAuthProvider,
  getAuth,
  GoogleAuthProvider,
  signInWithPopup,
} from "firebase/auth";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
export const firebaseConfig = {
  apiKey: "AIzaSyA8lJYsMyS2QMEdwCOT8tPcQcLqTSgZN64",
  authDomain: "metaverse-dbf2f.firebaseapp.com",
  projectId: "metaverse-dbf2f",
  storageBucket: "metaverse-dbf2f.appspot.com",
  messagingSenderId: "1070493232907",
  appId: "1:1070493232907:web:e7c703da88adbb36fd3c0e",
};

export const firebaseApp = initializeApp(firebaseConfig);
export const auth = getAuth(firebaseApp);

const googleProvider = new GoogleAuthProvider();
const facebookProvider = new FacebookAuthProvider();
export const signInWithGoogle = async () => {
  try {
    const res = await signInWithPopup(auth, googleProvider);
    const user = res.user;
    console.log("User from Google Response", user);
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};

export const signInWithFacebook = async () => {
  try {
    const res = await signInWithPopup(auth, facebookProvider);
    const user = res.user;
    console.log("User from Facebook Response", user);
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};
