import React, { useState, useEffect } from "react";
import classes from "./auth.module.css";
import logo from "../../Assets/Images/metaMainLogo.png";
import Avatar from "../../Assets/Images/Avatar.png";
import Avatar1 from "../../Assets/Images/Avatar1.png";
import Avatar2 from "../../Assets/Images/Avatar2.png";
import leftarrow from "../../Assets/Images/leftarrow.png";
import rightarrow from "../../Assets/Images/rightarrow.png";
import Img1 from "../../Assets/Images/img-1.png";
import Img2 from "../../Assets/Images/img-2.png";
import Img3 from "../../Assets/Images/img-3.png";
import Img4 from "../../Assets/Images/img-4.png";
import Img5 from "../../Assets/Images/img-5.png";
// import rightarrow from "../../Assets/Images/rightarrow.png";
import Carousel from "react-multi-carousel";
import { Typography, Box, Button } from "@mui/material";
import Slider from "react-slick";
import "./style.css";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import AlertBox from "../../Components/AlertBox";
import "./style.css";
import { Leftarrow, Rightarrow } from "../../Assets/Icons/icons";
import {
  getFriendList,
  pageLoader,
  setSelectedFriend,
} from "../../Redux/Actions/userActions";

let arr = [
  { id: 1, img: Avatar },
  { id: 2, img: Avatar1 },
  { id: 3, img: Avatar2 },
];

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 3,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1024, min: 850 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 850, min: 0 },
    items: 1,
  },
};

function ChooseFriend() {
  const { errorMessage, errorAlert } = useSelector((state) => state.userLogin);
  const [activeImage, setActiveImage] = useState(1);
  const [friendList, setFriendList] = useState([]);
  const [selectedItem, setSelectedItem] = useState();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { loggedUser } = useSelector((state) => state.userLogin);

  useEffect(() => {
    if (friendList.length > 0) {
      
      setSelectedItem(friendList[1]);
    }
  }, [friendList.length > 0]);

  const closeAlert = () => {
    dispatch({
      type: "USER_LOGIN_REQUEST_FAIL",
    });
  };

  const handleFriendList = () => {
    dispatch(getFriendList(setFriendList));
  };

  useEffect(() => {
    handleFriendList();
  }, []);

  const CustomButtonGroup = ({ next, previous }) => {
    return (
      <div>
        <img
          src={rightarrow}
          alt="rightarrow"
          className={classes.rightarrow}
          onClick={() => {
            next();
            handleAfterChange();
          }}
        />
        <img
          src={leftarrow}
          alt="leftarrow"
          className={classes.leftarrow}
          onClick={() => {
            previous();
            handleBeforeChange();
          }}
        />
      </div>
    );
  };

  const handleAfterChange = (previousSlide, _ref, name) => {
    
    const index = parseInt(activeImage) + 1;
    
    setActiveImage(
      index < friendList.length ? index : index - friendList.length
    );

    setSelectedItem(
      friendList[index < friendList.length ? index : index - friendList.length]
    );
  };

  const handleBeforeChange = (nextSlide, _ref2) => {

    const index = parseInt(activeImage) - 1;
    setActiveImage(index > -1 ? index : index + friendList.length);
    setSelectedItem(friendList[index > -1 ? index : index + friendList.length]);
  };
  
  const handleSubmit = () => {
    if (selectedItem) {
      
      const data = {
        Id: selectedItem?._id,
      };
      let stu_info = JSON.parse(localStorage.getItem("userToken"));
      let my_student_id = loggedUser?.studentInfo?._id || stu_info?.data[0]?._id;
      dispatch(pageLoader(true));
      dispatch(setSelectedFriend(my_student_id, data, navigate,selectedItem));
      localStorage.setItem("selectedFriend", JSON.stringify(selectedItem));
    }
  };

  return (
    <div className={classes.chooseavatar}>
      <div className="container pt-3">
        <img
          src={logo}
          alt="metaverse-logo"
          className={classes.metaverselogo}
        />
        <Box mt={6}>
          <Typography variant="h2" className={classes.authHeader}>
            Hi {loggedUser?.student?.first_name} {loggedUser?.student?.last_name}, Select a Friend
          </Typography>
        </Box>
        <div className="slider-container-friend">
          <Carousel
            responsive={responsive}
            autoPlaySpeed={1500}
            draggable={false}
            infinite={true}
            containerClass={classes.SliderContainer}
            arrows={false}
            customButtonGroup={<CustomButtonGroup />}
            // afterChange={(previousSlide, _ref) =>
            //   handleAfterChange(previousSlide, _ref, "left")
            // }
            // beforeChange={handleBeforeChange}
          >
            {Array.isArray(friendList) &&
              friendList.map((value, index) => (
                <Box
                  width={"85%"}
                  pt={2}
                  margin={"auto"}
                  className={`${index === activeImage && classes.activeFriend}`}
                >
                  <img
                    src={`${process.env.REACT_APP_API_URL}/${value.image}`}
                    width={"60%"}
                    alt="avatar"
                  />
                </Box>
              ))}
          </Carousel>
          {/* <Slider {...settings}>
                        {arr.map((value) => (
                            <Box width={"150px"} >
                                <img src={value.img} width={"70%"} />
                            </Box>
                        ))}
                    </Slider> */}
          {/* <img src={rightarrow} className={classes.rightarrowfriend} />
            <img src={leftarrow} className={classes.leftarrowfriend} /> */}
        </div>
        <div
          className={`mt-3 ${classes.loginSubmitBtnContainer}`}
          style={{ float: "right" }}
        >
          <Button
            variant="contained"
            className={`${classes.loginSubmitBtn}`}
            type="submit"
            sx={{ marginTop: "10px" }}
            onClick={() => handleSubmit()}
          >
            Next
          </Button>
        </div>
      </div>
      <img src={Img1} alt="svgSampleImage1" className={classes.imgone} />
      <img src={Img2} alt="svgSampleImage2" className={classes.imgtwo} />
      <img src={Img3} alt="svgSampleImage3" className={classes.imgthree} />
      <img src={Img4} alt="svgSampleImage4" className={classes.imgfour} />
      <img src={Img5} alt="svgSampleImage5" className={classes.imgfive} />
      <img src={Img5} alt="svgSampleImage6" className={classes.imgfive} />
    </div>
  );
}

export default ChooseFriend;
