import {
  Box,
  Typography,
  Button,
  Popover,
  MenuItem,
  Container,
  Grid,
  Tabs,
  Tab,
  InputAdornment,
  IconButton,
  Card,
  TextField,
} from "@mui/material";
import classes from "./parent.module.css";
import classes1 from "../PaymentFlow/payment.module.css";
import classes2 from "../myprofile/myprofile.module.css";
import Ellipse from "../../Assets/Images/Ellipse2.png";
import Subscription from "../../Assets/Images/subscription1.png";
import Diamond from "../../Assets/Images/diamond.png";
import Avatar from "../../Assets/Images/new-avatar.png";
import { GreenCheckedICon } from "../../Assets/Icons/icons";
import { ColorsList } from "../../Utilities/commonConstants";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import "./parent.css";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { splitFirstLetter } from "../../Utilities/commonfunctions";

const features = [
  "3 Unlocked Topics",
  "Lorem ipsum dolor sit amet",
  "Lorem ipsum dolor sit amet",
  "Lorem ipsum dolor sit amet",
];

const users = ["User One","User Two"];

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function ParentMyprofile() {
  const [tabOneValue, setTabOneValue] = useState(0);
  const [tabTwoValue, setTabTwoValue] = useState(0);
  const navigate = useNavigate();
  const {  userInfo } = useSelector((state) => state.userLogin);
  const handleChange = (event, newValue) => {
    setTabOneValue(newValue);
  };
  const handleChangeTwo = (event, newValue) => {
    setTabTwoValue(newValue);
  };
    console.log("userInfo =",userInfo)
  useEffect(() => {
    window.scrollTo({
      top:0,
      behavior:"smooth"
    })
  }, []);
  return (
    <>
      <Container>
        <Grid container mb={5}>
          <Typography className={classes.my_head}>My Profile</Typography>
          <Grid item xs={12} sm={12} md={12}>
            <Box className={classes.my_childrens}>
              <Grid container>
                <Grid item xs={12} sm={12} md={5}>
                  <Box
                    display={"flex"}
                    alignItems={"center"}
                    gap={"15px"}
                    height={"100%"}
                  >
                    {userInfo?.avatar_url ?
                      <img src={`https://api.meta.apps.org.in/api/${userInfo?.avatar_url}`} width={"100px"} className={classes.avatar_url} onClick={() => navigate("/updateavatar")} /> :
                      <Typography className={classes2.split_letters} >
                        {splitFirstLetter(`${userInfo?.students[0]?.parentInfo?.name}`)}
                      </Typography>
                    }
                    <Box>
                      <Typography className={classes.profile_name}>
                        {userInfo?.parentInfo?.name}
                      </Typography>
                      <Box position={"relative"}> 
                        <Typography className={classes.label}>
                          Mobile Number: <b>{userInfo?.phone}</b>
                        </Typography>
                        <Typography
                          className={`${classes.label} ${classes.before_line}`}
                        >
                          Email ID: <b>{userInfo?.email}</b>
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={4}></Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={3}
                  sx={{
                    display: "grid",
                    justifyContent: "end",
                    alignItems: "start",
                  }}
                >
                  {/* <Button className={classes.edit_details}>Edit Details</Button> */}
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>

        <Grid container spacing={4}>
          <Grid item xs={12} sm={12} md={6}>
            <Box height={"100%"}>
              <Typography className={classes.my_head}>
                Subscription Information
              </Typography>
              <Box className="my_switch_tabs">
                <Tabs
                  value={tabOneValue}
                  onChange={handleChange}
                  aria-label="basic tabs example"
                >
                  {Array.isArray(userInfo?.students) &&
                userInfo?.students?.map((datas, i) => {
                    return <Tab label={datas?.first_name} {...a11yProps(i)} />;
                  })}
                </Tabs>
              </Box>
              {Array.isArray(userInfo?.students) &&
                userInfo?.students?.map((datas, i) => {
                return (
                  <CustomTabPanel value={tabOneValue} index={i}>
                    <div style={{ position: "relative" }}>
                      <Card
                        className={`${classes1.subscriptionCardMain} ${classes1.planDetailsCard}`}
                      >
                        <div
                          className={classes1.headerContainer}
                          style={{ background: ColorsList[0] }}
                        >
                          <Typography className={classes1.cardHeader}>
                            {datas?.first_name}
                          </Typography>
                        </div>
                        {datas?.subscriptionInfo && Object.keys(datas?.subscriptionInfo).length > 0 ? 
                          <div className={classes1.cardContentContainer}>
                          <Typography className={classes1.cardPrice}>
                            £ {datas?.subscriptionInfo?.price} <span>/ {datas?.subscriptionInfo?.duration}</span>
                          </Typography>
                          <Typography
                            className={`mt-3 ${classes1.cardSubHeader}`}
                          >
                            What Inclued?
                          </Typography>
                          <div className={`mt-3 ${classes1.featuresContainer} ${classes.feature_height_two}`}>
                            {Array.isArray(datas?.subscriptionInfo?.features) &&
                            datas?.subscriptionInfo?.features.map((feature, index) => {
                              return (
                                <Box className={classes.feature_align}>
                                  <span>
                                  <GreenCheckedICon />
                                  </span>
                                <Typography
                                  key={index}
                                  className={`mt-2 ${classes1.cardSubContent}`}
                                >
                                  
                                  <span>{feature}</span>
                                </Typography>
                                </Box>
                              );
                            })}
                          </div>
                        </div> :
                        <Box minHeight={"230px"}>
                          <Typography fontWeight={"600"} fontSize={"23px"} padding={"38px"}>
                          No Subscription found
                          </Typography>
                        </Box>
                        
                      }
                      {datas?.subscriptionInfo &&
                        <Box
                          display={"flex"}
                          alignItems={"center"}
                          justifyContent={"space-around"}
                        >
                          {/* <Button className={classes.change_plan}>
                            Change Plan
                          </Button> */}
                          {/* <Button className={classes.renew_plan}>
                            Renew Plan
                          </Button> */}
                        </Box> }
                      </Card>
                      <img
                        src={Subscription}
                        className={classes.subscription_logo}
                      />
                    </div>
                  </CustomTabPanel>
                );
              })}
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <Box height={"100%"}>
              <Typography className={classes.my_head}>
                Redeem Diamond
              </Typography>
              <Box className="my_switch_tabs">
                <Tabs
                  value={tabTwoValue}
                  onChange={handleChangeTwo}
                  aria-label="basic tabs example"
                >
                  {Array.isArray(userInfo?.students) &&
                userInfo?.students?.map((datas, i) => {
                    return <Tab label={datas?.first_name} {...a11yProps(i)} />;
                  })}
                </Tabs>
              </Box>
              {Array.isArray(userInfo?.students) &&
                userInfo?.students?.map((datas, i) => {
                return (
                  <CustomTabPanel value={tabTwoValue} index={i}>
                    <div style={{ position: "relative" }}>
                      <Box className={classes.redeem_diamond}>
                        <Box>
                          <Typography fontSize={"24px"}>Diamond</Typography>
                          <Typography fontSize={"56px"}>{datas?.diamonds}</Typography>
                          <img src={Avatar} width={"140px"} />
                          <span>Joe</span>
                        </Box>
                        <Box mt={4}>
                          <Typography fontSize={"24px"} mb={4}>
                            Redeem point for <br /> {datas?.first_name}
                          </Typography>
                          {/* <Link to={"/parent-inventory"}>
                          <Button className={classes.change_plan}>
                            Buy Gifts
                          </Button>
                          </Link> */}
                        </Box>
                      </Box>
                      <img src={Diamond} className={classes.diamond_logo} />
                    </div>
                  </CustomTabPanel>
                );
              })}
            </Box>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}

export default ParentMyprofile;
