module.exports = {
    USER_LOGIN_REQUEST_SUCCESS: "USER_LOGIN_REQUEST_SUCCESS",
    USER_LOGIN_REQUEST_FAIL: "USER_LOGIN_REQUEST_FAIL",
    HEADER_NAME: "HEADER_NAME",
    PAGE_LOADER: "PAGE_LOADER",
    TOGGLE_SIDEBAR: "TOGGLE_SIDEBAR",
    USER_REGISTER_REQUEST_SUCCESS: "USER_RESGISTER_REQUEST_SUCCESS",
    USER_REGISTER_REQUEST_FAIL: "USER_REGISTER_REQUEST_FAIL",
    CLOSE_ALERT: "CLOSE_ALERT",
    FORGOT_PASSWORD_OTP: "FORGOT_PASSWORD_OTP",
    SET_STUDENT_FEIEND: "SET_STUDENT_FEIEND",
    LOGGEDIN_USER: "LOGGEDIN_USER"
}