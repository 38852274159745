import { Box, Dialog, Typography } from "@mui/material";
import './PopupStyles.css'
import Instruction from '../../Assets/Images/instruction.png';
import SuccessBg from '../../Assets/Images/success-Bg.png';
import CardOne from '../../Assets/Images/card-1.png';
import CardTwo from '../../Assets/Images/card-2.png';
import CardThree from '../../Assets/Images/card-3.png';
import { Button } from "react-bootstrap";

function InstructionPopup(props) {
    const {open,handleClose,instructionDetails,handleNextInstruction,handleSkipInstruction} = props;
    return(<>
    <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="instruction-popup"
      >
        
        <Typography className="main-title" >
            Instruction
            </Typography>
        <Box className="sub-instruction" >
            <Box className='instruction-bottom' >
                <Typography className="title-one">{instructionDetails?.title}</Typography>
                <Typography className="title-two">{instructionDetails?.content}</Typography>
                <img src={instructionDetails?.img} alt='success' />
                <Box display={"flex"} gap={"15px"} justifyContent={"center"}>
                    <Button className="skip-btn" onClick={handleSkipInstruction}>
                        Skip This
                    </Button>
                    <Button className="next-btn" onClick={handleNextInstruction} >
                        Next
                    </Button>
                </Box>
            </Box>
            
                <img src={CardOne} alt='success' className="card-one"/>
                <img src={CardTwo} alt='success' className="card-two"/>
                <img src={CardThree} alt='success' className="card-three"/>
        </Box>
        <img src={SuccessBg} alt='success' className="success-bg"/>
      </Dialog>
    </>)
}

export default InstructionPopup;