import React, { useEffect, useState } from "react";
import classes from "./payment.module.css";
import { Col, Row } from "react-bootstrap";
import {
  Typography,
  Card,
  Grow,
  TextField,
  Button,
  Divider,
  Box,
} from "@mui/material";
import logo from "../../Assets/Images/metaMainLogo.png";
import {
  CloseBtnIcon,
  ColouredStarIcon,
  GreenCheckedICon,
  ParashuteIcon,
} from "../../Assets/Icons/icons";
import { ColorsList, subscriptionList } from "../../Utilities/commonConstants";
import { getOrderDetails } from "../../Redux/Actions/subscriptionAction";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getCouponWithCode, initiatePayment } from "../../Redux/Actions/userActions";

const LevelDetails = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [errorAlert, setErrorAlert] = useState(false);
  const [successAlert, setSuccessAlert] = useState(false);
  const [coupon, setCoupon] = useState("");
  const [couponInfo, setCouponInfo] = useState("");
  const [myTotal, setMyTotal] = useState("");
  const { userInfo } = useSelector((state) => state.userLogin);
  const [applyCouponChecked, setApplyCouponChecked] = useState(false);
  const [initiatePaySuccess, SetInitiatePaySuccess] = useState("");
  const [initiatePayError, SetInitiatePayError] = useState("");
  const { orderDetails } = useSelector((state) => state.subscriptionDatas);
  const convenianceFee = 10;

  useEffect(() => {
    if (userInfo && userInfo?._id) {
      dispatch(getOrderDetails(userInfo?._id, setErrorAlert));
    }
  }, [userInfo]);

  const getTotalAmount = () => {
    const Amount = orderDetails?.subscriptionInfo?.price + convenianceFee;
    const discount1 = orderDetails?.subscriptionInfo?.price * 0.1;
    const discount2 = orderDetails?.subscriptionInfo?.price * 0.05;
    const total = Amount - discount1 - discount2;
    if (localStorage.getItem("coupon") && couponInfo) {
      let c_discound = (couponInfo?.discount /100)*orderDetails?.subscriptionInfo?.price
      // return total - orderDetails?.subscriptionInfo?.price * 0.05;
      return total - c_discound;
    }
    return total;
  };
  console.log("orderDetails =",orderDetails)
  const removeCoupon = () => {
    
    localStorage.removeItem("coupon");
    setApplyCouponChecked(false);
    setCoupon("");
    setCouponInfo("");
  };

  const handleApplycoupon = () => {
    localStorage.setItem("coupon", coupon);
    if (coupon) {   
      dispatch(getCouponWithCode(coupon,userInfo?._id,setApplyCouponChecked,applyCouponChecked,setCouponInfo))
    }
  };

  const handleSecretToekn = async () => {

          let userEntry = {
      user_id: userInfo?._id,
      order_id: orderDetails?._id,
      subscribed_by: "SCHOOL",
      coupon_code: coupon,
      amount: myTotal,
    };
    console.log("userEntry =",userEntry)
    console.log("userInfo =",userInfo)
    console.log("orderDetails =",orderDetails)
      dispatch(
          initiatePayment(userEntry, SetInitiatePaySuccess, SetInitiatePayError,navigate)
         );
        }
  useEffect(() => {
    if (orderDetails) {
      let _total = getTotalAmount();

      console.log("_total =",_total)
      setMyTotal(_total);
    }

  }, [couponInfo,orderDetails]);

  console.log(coupon,applyCouponChecked ,'------------- 172')
  useEffect(() => {
    if (localStorage.getItem("coupon")) {
      setCoupon(localStorage.getItem("coupon"));
      setApplyCouponChecked(false);
      // handleApplycoupon();
    }
    if (localStorage.getItem("coupon_info")) {
      
      let a = {
        discount : localStorage.getItem("coupon_info")
      };
      setCouponInfo(a)
    }
  }, []);
  return (
    <div>
      <img src={logo} className={classes.paymentlogo} alt="MetaverseLogo" />
      <span className={classes.guestStarIcon}>
        <ColouredStarIcon />
      </span>
      <span className={classes.ParashuteIcon}>
        <ParashuteIcon />
      </span>
      <div className={`${classes.planDetailsRow}`} > 
        <div md={6} className={classes.planDetailsLeftContainer}>
          <div className={classes.ContentContainer}>
            <Typography className={classes.cardPrice}>Level Details</Typography>
            <Card
              className={`mt-4 ${classes.subscriptionCardMain} ${classes.planDetailsCard}`}
            >
              <div
                className={classes.headerContainer}
                style={{ background: ColorsList[0] }}
              >
                <Typography className={classes.cardHeader}>
                  {orderDetails?.subscriptionInfo?.subscription_name}
                </Typography>
              </div>
              <div className={classes.cardContentContainer}>
                <Typography className={classes.cardPrice}>
                  £{orderDetails?.subscriptionInfo?.price}{" "}
                  <span>/ {orderDetails?.subscriptionInfo?.duration}</span>
                </Typography>
                <Typography className={`mt-3 ${classes.cardSubHeader}`}>
                  What Inclued?
                </Typography>
                <div className={`mt-3 ${classes.featuresContainer} ${classes.feature_height_two}`}>
                  { orderDetails?.subscriptionInfo?.features &&
                  orderDetails?.subscriptionInfo?.features?.map((feature, index) => {
                    return (
                      <Box className={classes.feature_align}>
                      <span>
                      <GreenCheckedICon />
                      </span>
                      <Typography
                      key={index}
                      className={`mt-2 ${classes.cardSubContent}`}
                    >
                      <span>{feature}</span>
                    </Typography>
                    </Box>
                    );
                  })}
                </div>
              </div>
            </Card>
          </div>
        </div>
        <div md={6} className={classes.planDetailsRighttContainer}>
          <div className={classes.textDetailsContainer}>
            <div>
              <Typography className={classes.cardPrice}>
                Payment Information
              </Typography>
              <div className={`mt-3 mb-2 ${classes.paymentDetailsMainDiv}`}>
                <div className={classes.paymentListDiv}>
                  <Typography className={classes.paymentSubText}>
                    Payment Method
                  </Typography>
                  <Typography
                    className={`${classes.cardHeader1} ${classes.paymentTextWeight}`}
                  >
                    £{orderDetails?.subscriptionInfo?.price}.00
                  </Typography>
                </div>
                <div className={classes.paymentListDiv}>
                  <Typography className={classes.paymentSubText}>
                    Discount Amount (5%)
                  </Typography>
                  <Typography
                    className={`${classes.cardHeader1} ${classes.paymentTextWeight}`}
                  >
                    - £{orderDetails?.subscriptionInfo?.price * 0.05}.00
                  </Typography>
                </div>
                {localStorage.getItem("coupon") ? (
                  <div
                    className={classes.paymentListDiv}
                    style={{ display: applyCouponChecked ? "none" : "flex" }}
                  >
                    <Typography className={classes.paymentSubText}>
                      {localStorage.getItem("coupon")} ({couponInfo?.discount}%)
                      <span
                        className={`${classes.applyButton} ${classes.remove}`}
                        onClick={() => removeCoupon()}
                      >
                        Remove
                      </span>
                    </Typography>
                    <Typography
                      className={`${classes.cardHeader1} ${classes.paymentTextWeight}`}
                    >
                      - £{(couponInfo?.discount /100)*orderDetails?.subscriptionInfo?.price}.00
                    </Typography>
                  </div>
                ) : (
                  <div
                    className={classes.paymentListDiv}
                    style={{ display: applyCouponChecked ? "none" : "flex" }}
                  >
                    <Typography className={classes.paymentSubText}>
                      Coupon Discount
                    </Typography>
                    <Typography
                      className={`${classes.applyButton}`}
                      onClick={() => setApplyCouponChecked(!applyCouponChecked)}
                    >
                      Apply Coupon
                    </Typography>
                  </div>
                )}
                <Grow
                  in={applyCouponChecked}
                  style={{
                    transformOrigin: "0 0 0",
                  }}
                  {...(applyCouponChecked ? { timeout: 1000 } : {})}
                >
                  <div
                    className={classes.Couponcontainer}
                    style={{ display: applyCouponChecked ? "flex" : "none" }}
                  >
                    <TextField
                      sx={{
                        "& legend": { display: "none" },
                        "& fieldset": { top: 0 },
                        marginBottom: "15px",
                        width: "310px",
                      }}
                      id="descriptionInput"
                      fullWidth
                      size="small"
                      placeholder="Enter Coupon Code here"
                      className={classes.CouponInput}
                      value={coupon}
                      onChange={(e) => {
                        setCoupon(e.target.value);
                      }}
                    />
                    <div className={`d-flex align-items-center`}>
                      <Typography
                        className={`${classes.applyButton}`}
                        onClick={handleApplycoupon}
                      >
                        Apply
                      </Typography>
                      <Button
                        variant="text"
                        className={classes.closeButton}
                        onClick={() =>{
                          setApplyCouponChecked(!applyCouponChecked)
                          removeCoupon()
                        }}
                      >
                        <CloseBtnIcon />
                      </Button>
                    </div>
                  </div>
                </Grow>
                <div className={classes.paymentListDiv}>
                  <Typography className={classes.paymentSubText}>
                    Convenience Fee
                  </Typography>
                  <Typography
                    className={`${classes.cardHeader1} ${classes.paymentTextWeight}`}
                  >
                    £{convenianceFee}.00
                  </Typography>
                </div>
                <div className={classes.paymentListDiv}>
                  <Typography className={classes.paymentSubText}>
                    10% Extra off
                  </Typography>
                  <Typography
                    className={`${classes.cardHeader1} ${classes.paymentTextWeight}`}
                  >
                    - £{orderDetails?.subscriptionInfo?.price * 0.1}.00
                  </Typography>
                </div>
              </div>
              <Divider />
              <div className={`mt-2`}>
                <div className={classes.paymentListDiv}>
                  <Typography className={classes.cardHeader1}>
                    Total Amount (Inc. Tax)
                  </Typography>
                  <Typography className={`${classes.cardHeader1}`}>
                    £{myTotal}.00
                  </Typography>
                </div>
              </div>
            </div>
            <div className={`mt-5 ${classes.makePaymentBtnContainer}`}>
              <Button
                className={classes.makePaymentBtn}
                variant="contained"
                // onClick={() => navigate("/payment-method")}
                onClick={handleSecretToekn}
              >
                Make Payment
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LevelDetails;
