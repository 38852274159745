import { Navigate, createBrowserRouter } from "react-router-dom";
import GuestLayout from "./Layout/guestLayout";
import Login from "./Pages/Auth/login";
import Home from "./Pages/Home/home";
import DefaultLayout from "./Layout/defaultLayout";
import Register from "./Pages/Auth/Register";
import MobileVerification from "./Pages/Auth/MobileVerification";
import StudentRegister from "./Pages/Auth/StudentRegister";
import ForgetPassword from "./Pages/Auth/ForgetPassword";
import ChooseAvatar from "./Pages/Auth/ChooseAvatar";
import ChooseFriend from "./Pages/Auth/ChooseFriend";
import SelectedFriend from "./Pages/Auth/SelectedFriend";
import Dashboard from "./Pages/dashboard/Dashboard";
import SubscriptionIndex from "./Pages/PaymentFlow/subscriptionIndex";
import FunctionalLayout from "./Layout/functionalLayout";
import LevelDetails from "./Pages/PaymentFlow/levelDetails";
import PaymentScreen from "./Pages/PaymentFlow/paymentScreen";
import Game from "./Pages/Games/game";
import PaymentReceipt from "./Pages/PaymentFlow/paymentReceipt";
import MyCode from "./Pages/dashboard/MyCode";
import Levels from "./Pages/dashboard/Levels";
import MyProfileHome from "./Pages/myprofile/MyProfileHome";
import MyAchievments from "./Pages/myprofile/MyAchievments";
import NewPassword from "./Pages/Auth/NewPassword";
import Inventory from "./Pages/myprofile/Inventory";
import Index404 from "./Pages/404";
import Progress from "./Pages/myprofile/Progress";
import ParentLayout from "./Layout/parentLayout";
import ParentDashboard from "./Pages/parent/ParentDashboard";
import ParentUser from "./Pages/parent/ParentUser";
import ParentMyprofile from "./Pages/parent/ParentMyprofile";
import ChangePassword from "./Pages/Auth/ChangePassword";
import GamePage from "./Pages/dashboard/GamePage";


const Router = createBrowserRouter([
    {
        path: "/",
        element: <GuestLayout />,
        children: [
            {
                path: "/",
                element: <Navigate to="/login" />,
            },
            {
                path: "/login",
                element: <Login />,
            },
            {
                path: "/register",
                element: <Register />,
            },
            {
                path: "/mobileverification",
                element: <MobileVerification />,
            },
            {
                path: "/studentregister",
                element: <StudentRegister />,
            },
            {
                path: "/newpassword",
                element: <NewPassword />,
            },
            {
                path: "/forgotpassword",
                element: <ForgetPassword />,
            },
        ],
    },
    {
        path: "/",
        element: <FunctionalLayout />,
        children: [
            {
                path: "/select-subscription",
                element: <SubscriptionIndex />,
            },
            {
                path: "/payment-details",
                element: <LevelDetails />,
            },
            {
                path: "/payment-method",
                element: <PaymentScreen />,
            },
        ],
    },
    {
        path: "/",
        element: <DefaultLayout />,
        children: [
            {
                path: "/",
                element: <Navigate to="/home" />,
            },
            {
                path: "/game-page",
                element: <Game />
            },
            {
                path: "/home",
                element: <Dashboard />,
            },
            {
                path: "/mycode",
                element: <MyCode />,
            },
            {
                path: "/levels",
                element: <Levels />,
            },
           
            {
                path: "/myprofile",
                element: <MyProfileHome />,
            },
            {
                path: "/myachievements",
                element: <MyAchievments />,
            },
            {
                path: "/inventory",
                element: <Inventory />,
            },
            {
                path: "/progress",
                element: <Progress />,
            },
            {
                path: "/invoice/:id",
                element: <PaymentReceipt />
            },
            {
                path: "/change-password",
                element: <ChangePassword />
            },
            {
                path: "*",
                element: <Index404 />,
            },
        ],
    },
    {
        path: "/gamepage",
        element: <GamePage />,
    },
    {
        path: "/chooseavatar",
        element: <ChooseAvatar />
    },
    {
        path: "/updateavatar",
        element: <ChooseAvatar />
    },
    {
        path: "/choosefriend",
        element: <ChooseFriend />
    },
    {
        path: "/selectedfriend",
        element: <SelectedFriend />
    },
    {
        path: "/",
        element: <ParentLayout />,
        children: [
            {
                path: "/parent-dashboard",
                element:<ParentDashboard />,
            },
            {
                path: "/parent-user",
                element:<ParentUser />,
            },
            {
                path: "/parent-myprofile",
                element:<ParentMyprofile />,
            },
            {
                path: "/parent-inventory",
                element:<Inventory />,
            },
            {
                path: "/change-password-parent",
                element: <ChangePassword />
            },
            {
                path: "/myachievements-parent",
                element: <MyAchievments />,
            },
        ]
    }
]);

export default Router;