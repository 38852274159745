import { createStore, combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import { userReducers } from "./Reducers/userReducers";
import { subscriptionReducer } from "./Reducers/subscriptionReducers";


const reducer = combineReducers({
  userLogin: userReducers,
  subscriptionDatas: subscriptionReducer,
});

const userInfoFromStorage = localStorage.getItem("userToken")
  ? JSON.parse(localStorage.getItem("userToken"))
  : null;

const initialState = {

  userLogin: {
    loggedUser: userInfoFromStorage && userInfoFromStorage?.data,
    userRole: userInfoFromStorage && userInfoFromStorage?.data && userInfoFromStorage?.data?.length > 0 && userInfoFromStorage?.data[0]?.role,
  }
};
const middleware = [thunk];

const store = createStore(
  reducer,
  initialState,
  composeWithDevTools(applyMiddleware(...middleware))
);

export default store;
