import React, { useState, useEffect } from "react";
import {
  Card,
  Typography,
  IconButton,
  InputAdornment,
  Button,
  Divider,
} from "@mui/material";
import classes from "./auth.module.css";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { CloseEyeIcon, OpenEyeIcon } from "../../Assets/Icons/icons";
import faceBookBtn from "../../Assets/Images/faceBookBtn.png";
import googleBtn from "../../Assets/Images/googleBtn.png";
import { signInWithFacebook, signInWithGoogle } from "../../Utilities/firebase";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  getLoginUserList,
  studentLogin,
} from "../../Redux/Actions/userActions";
import SelectUserPopup from "../popup/SelectUserPopup";

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [isPopup, setIsPopup] = useState(false);
  const [myRole, setMyRole] = useState("");
  const [usersList, setUsersList] = useState("");

  // const handleSignInwithGoogle = () => {
  //   const signInWithGoogle = async () => {
  //     try {
  //       const res = await signInWithPopup(auth, googleProvider);
  //       const user = res.user;
  //       const q = query(collection(db, "users"), where("uid", "==", user.uid));
  //       const docs = await getDocs(q);
  //       if (docs.docs.length === 0) {
  //         await addDoc(collection(db, "users"), {
  //           uid: user.uid,
  //           name: user.displayName,
  //           authProvider: "google",
  //           email: user.email,
  //         });
  //       }
  //     } catch (err) {
  //       console.error(err);
  //       alert(err.message);
  //     }
  //   };
  // };
  const handleClose = () => {
    setIsPopup(false);
  };

  const handleSubmit = () => {
    let userEntry = {
      username: email,
      password: password,
    };
    dispatch(getLoginUserList(userEntry, setUsersList, setIsPopup));
  };

  const handleLogin = (selected_role, _id) => {
    let body = {
      username: email,
      password: password,
      userRole: sessionStorage.getItem("myrole"),
      // student_id:""
    };
    if (selected_role === "STUDENT") {
      body["student_id"] = _id;
    }
    localStorage.setItem("selected_role", selected_role);
    dispatch(studentLogin(body, navigate, setMyRole));
    handleClose();
  };

  useEffect(() => {
    if (myRole !== "") {
      handleLogin();
    }
  }, [myRole]);

  return (
    <div className={classes.LoginMainDiv}>
      <Card className={classes.LoginMainCard}>
        <Typography variant="h2" className={classes.authHeader}>
          Login
        </Typography>
        <Typography
          variant="h6"
          className={`mt-2 ${classes.authHeaderSubText}`}
        >
          Please enter your mobile number/ Email and Password
        </Typography>
        <ValidatorForm
          useref="form"
          onSubmit={handleSubmit}
          className={`mt-5 mb-4 ${classes.formInputContainer}`}
        >
          <div>
            <TextValidator
              id="emailInput"
              fullWidth
              placeholder="Email ID / Mobile Number"
              className={`${classes.TextInput}`}
              value={email}
              size="small"
              focused
              onChange={(e) => {
                setEmail(e.target.value);
              }}
              validators={["required"]} // Add this line
              errorMessages={["Email Id or Mobile Number is required"]}
            />
          </div>
          <div className={`mt-4`}>
            <TextValidator
              id="emailInput"
              fullWidth
              placeholder="Password"
              className={`${classes.TextInput}`}
              value={password}
              type={showPassword ? "text" : "password"}
              size="small"
              focused
              onChange={(e) => {
                setPassword(e.target.value);
              }}
              validators={["required"]} // Add this line
              errorMessages={["Password is required"]}
              InputProps={{
                endAdornment: (
                  <InputAdornment
                    position="end"
                    className={classes.passwordInputAdornment}
                  >
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setShowPassword(!showPassword)}
                      edge="end"
                    >
                      {showPassword ? <CloseEyeIcon /> : <OpenEyeIcon />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <Typography
              className={classes.forgotPasswordText}
              onClick={() => navigate("/forgotpassword")}
            >
              Forgot Password?
            </Typography>
          </div>
          <div className={`mt-3 ${classes.loginSubmitBtnContainer}`}>
            <Button
              variant="contained"
              className={`${classes.loginSubmitBtn}`}
              type="submit"
            >
              Login
            </Button>
          </div>
        </ValidatorForm>
        {/* <Divider>Or Login with</Divider>
        <div className={`mt-4 ${classes.socialLoginBtnContainer}`}>
          <img
            src={faceBookBtn}
            alt="faceBookBtn"
            className={classes.faceBookBtn}
            onClick={() => signInWithFacebook()}
          />
          <img
            src={googleBtn}
            alt="googleBtn"
            className={classes.googleBtn}
            onClick={() => signInWithGoogle()}
          />
        </div> */}
      </Card>
      <Button
        className={classes.footerBtn}
        variant="contained"
        onClick={() => navigate("/register")}
      >
        Need an Account?
      </Button>
      <SelectUserPopup
        open={isPopup}
        handleClose={handleClose}
        handleLogin={handleLogin}
        userLists={usersList}
      />
    </div>
  );
};

export default Login;
