import { Box, Dialog, Typography } from "@mui/material";
import './PopupStyles.css'
import { Button } from "react-bootstrap";
import { CloseBtnIcon, LiteHeartIcon, RoleParentIcon, RoleStudentIcon } from "../../Assets/Icons/icons";

function SelectUserPopup(props) {
    const { open, handleClose ,handleLogin,userLists} = props;
    return (<>
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            className="select-user-popup"
        >
            <Box className="sub-instruction" >
                <Typography className="select-user-title" >
                    Hey! This mobile number is register
                </Typography>
                <Typography className="select-user-content">
                    This mobile number is already register as Student and Parent Account both please select the user type
                </Typography>
                <Box display={"flex"} justifyContent={"space-around"} p={1} alignItems={"center"} gap={"15px"} sx={{overflowX:"scroll"}} mt={3}>
                    {Array.isArray(userLists?.studnets) &&
                    userLists?.studnets.map((datas,i)=>(
                        <Box className="user-card" >
                        <Box>
                            <RoleStudentIcon />
                        </Box>
                        <Typography sx={{wordBreak:"break-all",width:"150px"}} >
                          {datas.first_name}{" "}{datas.last_name} <br /> <span style={{fontSize:"15px"}}>(Student)</span>
                        </Typography>
                        <Button onClick={()=>{
                            sessionStorage.setItem("myrole","STUDENT");
                            handleLogin("STUDENT",datas._id);
                        }} >
                            Continue
                        </Button>
                    </Box>
                    ))
                    }
                    <Box className="user-card" >
                        <Box>
                            <RoleParentIcon />
                        </Box>
                        <Typography>
                            {userLists?.parent?.name} <br /> <span style={{fontSize:"15px"}}>(Parent)</span>
                        </Typography>
                        <Button onClick={()=>{
                            sessionStorage.setItem("myrole","PARENT");
                            handleLogin("PARENT");
                        }}>
                            Continue
                        </Button>
                    </Box>
                </Box>
            </Box>

            <Box className="close-icon" onClick={handleClose}>
                <CloseBtnIcon />
            </Box>
            <Box className="heart-icon">
                <LiteHeartIcon />
            </Box>
        </Dialog>
    </>)
}

export default SelectUserPopup;