module.exports = {
  subscriptionList: [
    {
      level: "level 1",
      price: 50,
      duration: "6 Month",
      features: [
        "3 Unlocked Topics",
        "Lorem ipsum dolor sit amet",
        "Lorem ipsum dolor sit amet",
        "Lorem ipsum dolor sit amet",
      ],
      id: 1,
    },
    {
      level: "level 2",
      price: 100,
      duration: "6 Month",
      features: [
        "All Unlocked Topics",
        "Lorem ipsum dolor sit amet",
        "Lorem ipsum dolor sit amet",
        "Lorem ipsum dolor sit amet",
        "Lorem ipsum dolor sit amet",
      ],
      id: 1,
    },
    {
      level: "level 3",
      price: 150,
      duration: "year",
      features: [
        "All Unlocked Topics",
        "Lorem ipsum dolor sit amet",
        "Lorem ipsum dolor sit amet",
        "Lorem ipsum dolor sit amet",
        "Lorem ipsum dolor sit amet",
      ],
      id: 1,
    },
    {
      level: "level 4",
      price: 200,
      duration: "year",
      features: [
        "All Unlocked Topics",
        "Lorem ipsum dolor sit amet",
        "Lorem ipsum dolor sit amet",
        "Lorem ipsum dolor sit amet",
        "Lorem ipsum dolor sit amet",
      ],
      id: 1,
    },
  ],
  ColorsList: ["#FFADDB", "#FFC700", "#BA9BFF", "#6AD7E0"],
};
