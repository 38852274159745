import { Box, Button, Container, Grid, Typography } from "@mui/material";
import classes from './myprofile.module.css'
import TopicOne from "../../Assets/Images/topic1.png";
import TopicTwo from "../../Assets/Images/topic2.png";
import TopicThree from "../../Assets/Images/topic3.png";
import Progress1 from "../../Assets/Images/progress-1.png";
import Progress2 from "../../Assets/Images/progress-2.png";
import Progress3 from "../../Assets/Images/progress-3.png";
import Progress4 from "../../Assets/Images/progress-4.png";
import Progress5 from "../../Assets/Images/progress-5.png";
import Progress6 from "../../Assets/Images/progress-6.png";
import Progress7 from "../../Assets/Images/progress-7.png";
import Progress8 from "../../Assets/Images/progress-8.png";
import Progress9 from "../../Assets/Images/progress-9.png";
import Progress10 from "../../Assets/Images/progress-10.png";
import Progress11 from "../../Assets/Images/progress-11.png";
import GoldStar from "../../Assets/Images/Gold-star.png";
import GreyStar from "../../Assets/Images/Grey-star.png";
import BrownStar from "../../Assets/Images/brown-star.png";
import ProgressStar from "../../Assets/Images/progress-star.png";
import { ViloetBackArrow } from "../../Assets/Icons/icons";
import { useNavigate } from 'react-router-dom';
import Ballon from "../../Assets/Images/game-ballon.png";
import { useState,useEffect } from "react";
import { useSelector } from "react-redux";

const progress_one = [
    {
        img: Progress1,
        content: "Picture puzzles",
        star:GoldStar,
    },
    {
        img: Progress2,
        content: "Sequencing Puzzles",
        star:BrownStar,
    },
    {
        img: Progress3,
        content: "Join Dot to dot",
        star:GreyStar,
    },
    {
        img: Progress4,
        content: "Loops",
        star:GoldStar,
    },
    {
        img: Progress1,
        content: "Sort objects",
        star:BrownStar,
    },
];

const progress_two = [
    {
        img: Progress5,
        content: "Drag shapes to make animal houses",
        star:GoldStar,
    },
    {
        img: Progress6,
        content: "Life cycle of a frog / butterfly",
        star:BrownStar,
    },
    {
        img: Progress7,
        content: "Help the creature reach the food.",
        star:GreyStar,
    },
    {
        img: Progress8,
        content: "Drag and drop parts of a robot.",
        star:GoldStar,
    },
    {
        img: Progress5,
        content: "Create a pizza recipe",
        star:BrownStar,
    },
];

const progress_three = [
    {
        img: Progress9,
        content: "What is the Internet?",
        star:GoldStar,
    },
    {
        img: Progress10,
        content: "What is a Network?",
        star:BrownStar,
    },
    {
        img: Progress11,
        content: "What is a computer used for?",
        star:GreyStar,
    }
];

function Progress() {
    const navigate = useNavigate();
    const { userInfo, userRole, error, loggedUser } = useSelector((state) => state.userLogin);
    useEffect(() => {
        window.scroll({
          top:0,
          behavior:"smooth"
        })
      
      }, [])
    return (<Box position={"relative"} >
        <Container sx={{ paddingBottom: "15px" }} >
            <Typography className={classes.main_title} >
                    <Box sx={{cursor:"pointer",display:"inline-block"}} onClick={()=>navigate(-1)}>
                    <ViloetBackArrow  />
                    </Box >
                My Progress/Badges
            </Typography>
            <Grid container spacing={3} >
                <Grid item xs={12} sm={12} md={8}>
                    <Box className={classes.progress_profile_wrapper} >
                        <Grid container>
                            <Grid item xs={12} sm={12} md={6}>
                                <Box className={classes.progress_profile} >
                                    <Typography>
                                        Hey! {userInfo?.studentInfo?.first_name} {userInfo?.studentInfo?.last_name}
                                    </Typography>
                                    <span>
                                        You are doing a fentastic job! <br /> Keep up the good work!!!
                                    </span>
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={12} md={1}></Grid>
                            <Grid item xs={12} sm={12} md={4}>
                                <img src={ProgressStar} alt="image" />
                            </Grid>
                            <Grid item xs={12} sm={12} md={1}></Grid>
                        </Grid>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={4}>
                    <Box className={classes.your_progress} >
                        <h1 className={classes.profile_name}>Your Progress</h1>
                        <Box>
                            <Typography className={classes.progress_level} textAlign={"end"}>Level  {userInfo?.studentInfo?.level}</Typography>
                            <Box height={"12px"} bgcolor={"#557CCB"} borderRadius={"20px"} >
                                <Typography height={"100%"} width={`${userInfo?.progress}%`} bgcolor={"#FFCB00"} borderRadius={"20px"}></Typography>
                            </Box>
                            <Typography className={classes.progress_level}> {userInfo?.progress}% Progress</Typography>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
            <Grid container spacing={2} mt={2} >
                <Grid item xs={12} sm={12} md={4}>
                    <Box className={classes.first_box} >
                        <Box display={"flex"} alignItems={"center"}  p={2} gap={"12px"} >
                            <img src={TopicOne} alt="image" width={"90px"} />
                            <Box>
                                <Typography className={classes.progress_title}>Computer Programming</Typography>
                                <img src={ProgressStar} alt="image" width={"100px"} />
                            </Box>
                        </Box>
                        <hr />
                        <Box p={2} pt={0}>
                            {progress_one.map((data, i) => {
                                return <Box key={i} className={classes.d_flex} justifyContent={"space-between"} my={2} >
                                    <Box className={classes.d_flex} gap={"10px"} >
                                        <img src={data.img} alt="image" />
                                        <Typography className={classes.progress_sub_title} >{data.content}</Typography>
                                    </Box>
                                    <img src={data.star} alt="image" width={"60px"} />
                                </Box>
                            })}
                        </Box>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={4}>
                <Box className={classes.first_box} >
                        <Box display={"flex"} alignItems={"center"} p={2} gap={"12px"} >
                            <img src={TopicTwo} alt="image" width={"110px"} />
                            <Box>
                                <Typography className={classes.progress_title}>Algorithms</Typography>
                                <img src={ProgressStar} alt="image" width={"100px"} />
                            </Box>
                        </Box>
                        <hr />
                        <Box p={2} pt={0}>
                            {progress_two.map((data, i) => {
                                return <Box key={i} className={classes.d_flex} justifyContent={"space-between"} my={2} >
                                    <Box className={classes.d_flex} gap={"10px"} >
                                        <img src={data.img} alt="image" />
                                        <Typography className={classes.progress_sub_title} >{data.content}</Typography>
                                    </Box>
                                    <img src={data.star} alt="image" width={"60px"} />
                                </Box>
                            })}
                        </Box>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={4}>
                <Box className={classes.first_box} >
                        <Box display={"flex"} alignItems={"center"} p={2} gap={"12px"} >
                            <img src={TopicThree} alt="image" width={"90px"} />
                            <Box>
                                <Typography className={classes.progress_title}>Digital Literacy</Typography>
                                <img src={ProgressStar} alt="image" width={"100px"} />
                            </Box>
                        </Box>
                        <hr />
                        <Box p={2} pt={0}>
                            {progress_three.map((data, i) => {
                                return <Box key={i} className={classes.d_flex} justifyContent={"space-between"} my={2} >
                                    <Box className={classes.d_flex} gap={"10px"} >
                                        <img src={data.img} alt="image" />
                                        <Typography className={classes.progress_sub_title} >{data.content}</Typography>
                                    </Box>
                                    <img src={data.star} alt="image" width={"60px"} />
                                </Box>
                            })}
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </Container>
        <img src={Ballon} alt="image" className={classes.progress_ballon}  />
    </Box>)
}

export default Progress;