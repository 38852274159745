import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

function GamePage() {
  const [iframeUrl, setIframeUrl] = useState("");
  const [iframe, setIframe] = useState(false);
  const { userInfo, userRole, error, loggedUser } = useSelector(
    (state) => state.userLogin
  );
  let _token = JSON.parse(localStorage.getItem("userToken"));
  const navigate = useNavigate();
  const location = useLocation();
  const iframe_details = location?.state?.iframe_infos; 

  console.log("iframe_details =",iframe_details)
  window.onmessage = function (e) {
    console.log(e);
    if (e.data == "from_game") {
      setIframe(false);
      if (iframe_details?.level_id && iframe_details?.course_id) {
        navigate("/levels");
     } else {
      navigate("/home");
       
     }
      // window.close();
    }
  };

  function handleCardClick(datas, index) {
    console.log("166");

    setIframe(true);
    let studentId =
      userInfo?.studentInfo?._id ||
      _token?.data[0]?._id ||
      _token?.data?.student?._id;
      let iframe_url = "";
      if (iframe_details?.level_id && iframe_details?.course_id) {
        
         iframe_url = `https://api.meta.apps.org.in/api/uploads/gamefile/?level_id=${iframe_details?.level_id}&course_id=${iframe_details?.course_id}&student_id=${studentId}&token=${_token?.accessToken}`;
      } else {
         iframe_url = `https://api.meta.apps.org.in/api/uploads/gamefile/?level_id=${iframe_details?.level_id}&token=${_token?.accessToken}`;
        
      }
    console.log("iframe_url =", iframe_url);
    setIframeUrl(iframe_url);
  }

  useEffect(() => {
    
    if (iframe_details) {
      handleCardClick();
    } else {
      navigate("/home");
    }
  }, []);

  return (
    <div>
      {iframeUrl && iframe ? (
        <div style={{ height: "100vh" }}>
          <iframe
            id="game_frame"
            allowFullScreen={true}
            className="col d-block h-100 border-0 m-0 p-0"
            height={"auto"}
            style={{ zIndex: 12 }}
            width={"100%"}
            src={iframeUrl}
          ></iframe>
        </div>
      ) : (
        <h1> Loading....</h1>
      )}
    </div>
  );
}

export default GamePage;
