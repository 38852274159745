import React, { useEffect, useState } from "react";
import classes from "./payment.module.css";
import { Button, Card, Typography } from "@mui/material";
import { Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { useNavigate } from "react-router";
import { pageLoader } from "../../Redux/Actions/userActions";
import {
  downloadReceipt,
  getInvoiceDetails,
} from "../../Redux/Actions/subscriptionAction";
import { CheckCircleIcon } from "../../Assets/Icons/icons";

const PaymentReceipt = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [errorAlert, setErrorAlert] = useState(false);
  const [successAlert, setSuccessAlert] = useState(false);
  const { invoice } = useSelector((state) => state.subscriptionDatas);

  const handleDownloadReceipt = () => {
    dispatch(pageLoader(true));
    dispatch(downloadReceipt(invoice?._id, setErrorAlert, setSuccessAlert));
  };

  useEffect(() => {
    const path = window.location.pathname.split("/");
    dispatch(getInvoiceDetails(path[path.length - 1], setErrorAlert));
  }, []);


  return (
    <div
      className={`mt-5`}
      style={{
        display: "flex",
        justifyContent: "space-evenly",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Card style={{ width: "50%" }}>
        <div className={classes.HeaderContainer}>
          <Typography className={classes.ReceiptMainHeading}>
            {invoice?.subscriptionInfo?.subscription_name}
          </Typography>
          <div>
            <Typography className={classes.cardHeader2}>
              £{invoice?.subscriptionInfo?.price}
              <span className={classes.cardSubHeader2}>
                / {invoice?.subscriptionInfo?.duration}
              </span>
            </Typography>
            <Typography
              className={`${classes.cardHeader1} mt-2`}
              style={{
                display: "flex",
                alignItems: "center",
                columnGap: "5px",
              }}
            >
              <CheckCircleIcon />
              <span>Paid Successfully</span>
            </Typography>
          </div>
        </div>
        <div className={classes.ReceiptMainContainer}>
          <Row style={{ rowGap: "50px" }}>
            <Col xs={8}>
              <Typography className={classes.cardHeader1}>Mobile</Typography>
              <Typography className={classes.SomeImportantText}>
                {invoice?.userInfo?.phone}
              </Typography>
            </Col>
            <Col xs={4}>
              <Typography className={classes.cardHeader1}>Paid On</Typography>
              <Typography className={classes.SomeImportantText}>
                {moment(invoice?.invoice_date).format("D MMMM YYYY")}
              </Typography>
            </Col>
            <Col xs={8}>
              <Typography className={classes.cardHeader1}>Email ID</Typography>
              <Typography className={classes.SomeImportantText}>
                {invoice?.userInfo?.email}
              </Typography>
            </Col>
            <Col xs={4}>
              <Typography className={classes.cardHeader1}>
                Transaction ID
              </Typography>
              <Typography className={classes.SomeImportantText}>
                {invoice?.order_id}
              </Typography>
            </Col>
          </Row>
        </div>
        <div className={classes.ReceiptSubHeaderContainer}>
          <Row>
            <Col xs={8}>
              <Typography className={classes.cardHeader1}>
                Description
              </Typography>
            </Col>
            <Col xs={4}>
              <Typography className={classes.cardHeader1}>Amount</Typography>
            </Col>
          </Row>
        </div>
        <div className={classes.ReceiptMainContainer1}>
          <Row>
            <Col xs={8}>
              <Typography
                className={classes.SomeImportantText}
                style={{ width: "80%" }}
              >
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod
              </Typography>
            </Col>
            <Col xs={4}>
              <Typography className={classes.cardHeader2}>
                £{invoice?.subscriptionInfo?.price}.00
              </Typography>
            </Col>
          </Row>
        </div>
      </Card>
      <div className={`mt-3 ${classes.ReceiptbtnContainer}`}>
        <Button
          variant="outlined"
          className={classes.DownloadReceipt}
          style={{
            borderColor: "var(--forgot-password-text-color)",
            color: "var(--forgot-password-text-color)",
          }}
          const
          onClick={handleDownloadReceipt}
        >
          Download Receipt
        </Button>
        <Button
          variant="contained"
          className={classes.DownloadReceipt}
          style={{ backgroundColor: "var(--forgot-password-text-color)" }}
          onClick={() => navigate("/home")}
        >
          Go to Home
        </Button>
      </div>
    </div>
  );
};

export default PaymentReceipt;
