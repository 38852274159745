import React, { useEffect } from "react";
import classes from "./layout.module.css";
import { Outlet, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import AlertBox from "../Components/AlertBox";
import { getUserByToken } from "../Redux/Actions/userActions";

const FunctionalLayout = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { errorMessage, errorAlert, successMessage, successAlert } =
    useSelector((state) => state.userLogin);
    const token = JSON.parse(localStorage.getItem("userToken"));

  const closeAlert = () => {
    dispatch({
      type: "USER_LOGIN_REQUEST_FAIL",
    });
  };

  useEffect(() => {
    // console.log("token", token);
    if (token) {
      dispatch(getUserByToken(navigate,token?.data[0]?._id));
    }
  }, []);

  return (
    <div className={classes.DefaultLayoutMaindiv}>
      <Outlet />
      {errorMessage && errorAlert && (
        <AlertBox
          message={errorMessage}
          type="error"
          onCloseFunction={closeAlert}
        />
      )}
    </div>
  );
};

export default FunctionalLayout;
