import { Box, Container, Grid, Typography, Button } from "@mui/material";
import classes from "./dashboard.module.css";
import {
  DiamondIcon,
  LockIcon,
  RefreshIcon,
  ViloetBackArrow,
} from "../../Assets/Icons/icons";
import LevelImg from "../../Assets/Images/levels.png";
import InactiveStar from "../../Assets/Images/inactive-star.png";
import ActiveStar from "../../Assets/Images/active-star.png";
import LevelCloud from "../../Assets/Images/level-cloud.png";
import LevelBallon from "../../Assets/Images/game-ballon.png";
import LevelStar from "../../Assets/Images/level-star.png";
import ActiveStar1 from "../../Assets/Images/ActiveStar1.png";
import ActiveStar2 from "../../Assets/Images/ActiveStar2.png";
import InActiveStar1 from "../../Assets/Images/InActiveStar1.png";
import InActiveStar2 from "../../Assets/Images/InActiveStar2.png";
import InsImg from "../../Assets/Images/ins-img.png";
import Instruction from "../../Assets/Images/instruction.png";
import BottomContent from "./BottomContent";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import "./profile.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  gamesProgress,
  storeGamesProgress,
} from "../../Redux/Actions/userActions";
import InstructionPopup from "../popup/InstructionPopup";

let dummyArr = [
  { num: 1, level: 2 },
  { num: 1, level: 2 },
  { num: 0 },
  { num: 1, level: 2 },
];

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 4,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 4,
  },
  tablet: {
    breakpoint: { max: 1024, min: 850 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 850, min: 0 },
    items: 1,
  },
};

const stars = [
  [InActiveStar1, InActiveStar2, InActiveStar1],
  [ActiveStar1, InActiveStar2, InActiveStar1],
  [ActiveStar1, ActiveStar2, InActiveStar1],
  [ActiveStar1, ActiveStar2, ActiveStar1],
];

function Levels() {
  const [myAllLevels, setMyAllLevels] = useState([]);
  const [levelDetail, setLevelDetail] = useState("");

  const [instructionPop, setInstructionPop] = useState(false);
  const [gameFlag, setGameFlag] = useState(false);
  const [instructionPopDetails, setInstructionPopDetails] = useState({
    title: "Picture puzzles",
    content: "Connect (drag and drop) visual blocks to form a shape.",
    img: Instruction,
  });
  const { userInfo, userRole, error, loggedUser } = useSelector(
    (state) => state.userLogin
  );
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const singleInfo = location?.state?.singleGameDetail;
  let _token = JSON.parse(localStorage.getItem("userToken"));
  let _details = JSON.parse(sessionStorage.getItem("singleinfo"));

  function handlePresent(i) {
    const isPreset = myAllLevels.completedLevels[i-1];
    if (isPreset && isPreset.completed) {
      return true;
    } else {
      return false;
    }
  }

  function handleClose() {
    setInstructionPop(false);
    setInstructionPopDetails({
      ...instructionPopDetails,
      title: "Picture puzzles",
      content: "Connect (drag and drop) visual blocks to form a shape.",
      img: Instruction,
    });
  }

  function handleSkipInstruction() {
    handleClose();
    handleOpenGame();
  }

  function handleNextInstruction() {
    if (instructionPopDetails.title.includes("puzzles")) {
      setInstructionPopDetails({
        ...instructionPopDetails,
        title: "Coding Instruction",
        content:
          "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.",
        img: InsImg,
      });
    } else {
      handleClose();
      handleOpenGame();
    }
  }

  function handleLevelPercentage(level) {
    console.log(level,'level');
    return (
      <>
        <Box sx={{ userSelect: "none" }}>
          {stars[level]?.map((star, index) => (
            <img
              key={index}
              src={star}
              alt="star"
              className={index === 1 ? classes.middle_img : ""}
            />
          ))}
        </Box>
      </>
    );
  }
  console.log(singleInfo,'141');
  function handleGamesProgress() {
    let route_info = "";
    if (singleInfo) {
     sessionStorage.setItem("singleinfo",JSON.stringify(singleInfo));
    }
    let _details = JSON.parse(sessionStorage.getItem("singleinfo"));
    let _token = JSON.parse(localStorage.getItem("userToken"));
    const body = {
      courseId: singleInfo?._id || _details?._id,
      studentId: userInfo?.studentInfo?._id || _token?.data[0]?._id || _token?.data?.student?._id,
    };
    dispatch(gamesProgress(body, setMyAllLevels, _token?.accessToken));
  }

  function handleStoreGameProgress(level_id) {
    let _token = JSON.parse(localStorage.getItem("userToken"));
    const body = {
      levelId: levelDetail || level_id,
      courseId: singleInfo?._id,
      studentId: userInfo?.studentInfo?._id || _token?.data[0]?._id,
      starsEarned: 2,
      diamondsEarned: 11,
      completed: true,
    };
    // dispatch(storeGamesProgress(body, _token?.accessToken,setGameFlag));

  };

  function handleOpenGame() {
    let _details = JSON.parse(sessionStorage.getItem("singleinfo"));
    console.log("_details =",_details)
    let body = {
      level_id:levelDetail,
      course_id:singleInfo?._id || _details?._id,
    }
    navigate("/gamepage",{state:{iframe_infos:body}})
  }

  function handleCardClick(datas,index) {
    // console.log("166");
    // let test_token = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6ImthbHlhbkBnbWFpbC5jb20iLCJwaG9uZSI6Ijg4ODg4ODg4ODAiLCJ1c2VyIjoiNjY2ZmU2NDI0NWVkMGY0YjkyOGUzMTI0IiwiaWF0IjoxNzE4NjI4NTM2LCJleHAiOjE3MTkyMzMzMzZ9.Nod1HaM_zWBfNpEj7Y8Egkh_LISR_Ik4W_WtwhVjuU4"
    // setIframe(true);
    // let studentId = userInfo?.studentInfo?._id || _token?.data[0]?._id || _token?.data?.student?._id;
    // let iframe_url = `https://api.meta.apps.org.in/api/uploads/gamefile/?level_id=${datas._id}&course_id=${singleInfo?._id}&student_id=${studentId}&token=${test_token}`
    // console.log("iframe_url =",iframe_url)
    // setIframeUrl(iframe_url);
    if (datas?.completed) {
      alert("You have already played this game")
    } else {
      setLevelDetail(datas._id);
      setInstructionPop(true);

    }

    // if (datas.completed) {
    //   handleStoreGameProgress(datas._id);
    // } else {
    //   setLevelDetail(datas._id);
    //   setInstructionPop(true);
    // }
  };

  function handleCompletedCount() {
    let arr = myAllLevels?.completedLevels?.filter((datas)=>datas.completed === true);
    console.log(arr);
    if (arr) {
      return arr.length;
    } else {
      return 0;
    }
  }

  useEffect(() => {
    handleGamesProgress();
  }, []);



  useEffect(() => {
    if (gameFlag) {
      setGameFlag(false);
      handleGamesProgress();
    }
  }, [gameFlag]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  return (
   <div>
   
       <>
       <Box className={classes.row_one}>
         <Box className={classes.row_one_left}>
           
             <Box onClick={()=>navigate(-1)} sx={{cursor:"pointer"}} >
             <ViloetBackArrow />
             </Box>
 
           <Box
             sx={{
               display: "grid",
               placeItems: "center",
               height: "75px",
               bgcolor: "#fff",
             }}
           >
             <img src={LevelImg} alt="levels" />
           </Box>
           <Box>
             <Typography>{singleInfo?.gameInfo?.name || _details?.gameInfo?.name}</Typography>
             <span>Level {handleCompletedCount()} Completed</span>
           </Box>
         </Box>
         <Box className={classes.row_one_rigth}>
           <Box
             display={"flex"}
             justifyContent={"space-between"}
             alignItems={"center"}
           >
             <Typography>Lv.{userInfo?.studentInfo?.level}</Typography>
             <Typography>
             {userInfo?.studentInfo?.diamonds} <DiamondIcon />{" "}
             </Typography>
           </Box>
           <Box bgcolor={"#FFF1EB"} borderRadius={"6px"} height={"10px"} mt={2}>
             <Typography
               borderRadius={"6px"}
               bgcolor={"#854DFF"}
               width={`${userInfo?.progress}%`}
               height={"100%"}
             ></Typography>
           </Box>
         </Box>
       </Box>
       <Box className={`${classes.row_two} level-cards`}>
         <Box className={classes.swipper_container}>
           <Carousel
             responsive={responsive}
             autoPlaySpeed={1500}
             infinite={true}
             arrows={true}
           >
             {Array.isArray(myAllLevels?.completedLevels) &&
               myAllLevels?.completedLevels.map((datas, index) => {
                 return (
                   <Box
                     className={
                       datas.completed
                         ? `${classes.card_open} ${classes.card_open_active}`
                         : `${classes.card_open}`
                     }
                     onClick={() => {
                       handleCardClick(datas,index)
                     }}
                     sx={{
                       pointerEvents:
                       datas.completed || handlePresent(index) || index === 0 ? ""
                           : "none",
                     }}
                   >
                     {datas.completed ? (
                       handleLevelPercentage(datas.stars_earned)
                     ) : (
                       <Box sx={{ userSelect: "none" }}>
                         <img src={InActiveStar1} alt="cloud" />
                         <img
                           src={InActiveStar2}
                           alt="cloud"
                           className={classes.middle_img}
                         />
                         <img src={InActiveStar1} alt="cloud" />
                       </Box>
                     )}
                     <Typography
                       className={
                         datas.completed
                           ? `${classes.mylevel} ${classes.card_active}`
                           : `${classes.mylevel}`
                       }
                     >
                       {datas.level_name}
                     </Typography>
                     <Box
                       className={
                         datas.completed
                           ? `${classes.reward} ${classes.reward_active}`
                           : `${classes.reward}`
                       }
                     >
                       <Typography>Reward</Typography>
                       <span>
                         {datas.completed ?datas.diamonds_earned :datas.diamonds} <DiamondIcon />
                       </span>
                     </Box>
                     <Box
                       className={
                         datas.completed
                           ? classes.btm_icons
                           : `${classes.btm_icons} ${classes.lock_icon}`
                       }
                     >
                       {datas.completed || handlePresent(index) || index === 0 ? <RefreshIcon /> : <LockIcon />}
                     </Box>
                   </Box>
                 );
               })}
           </Carousel>
         </Box>
         <img src={LevelCloud} alt="cloud" className={classes.cloud_img} />
         <img src={LevelBallon} alt="cloud" className={classes.ballon_img} />
         <img src={LevelStar} alt="cloud" className={classes.star_img} />
       </Box>
       <Container sx={{ marginTop: "-80px" }}>
         <BottomContent />
       </Container>
       <InstructionPopup
         open={instructionPop}
         handleClose={handleClose}
         instructionDetails={instructionPopDetails}
         handleNextInstruction={handleNextInstruction}
         handleSkipInstruction={handleSkipInstruction}
       />
     </>
   </div>
  );
}

export default Levels;
