import { Typography, Button, InputAdornment, IconButton, Box } from "@mui/material";
import React, { useState } from "react";
import classes from "./index.module.css";
import { Col } from "react-bootstrap";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { CloseEyeIcon, OpenEyeIcon } from "../../Assets/Icons/icons";
import { useDispatch, useSelector } from "react-redux";
import {
    changePasswordStudent,
  pageLoader,
  setHeaderName,
} from "../../Redux/Actions/userActions";
import { useNavigate } from "react-router";
import AlertBox from "../../Components/AlertBox";
import ErrAlertBox from "../../Components/ErrAlertBox";
import SuccessAlertBox from "../../Components/SuccessAlertBox";

const ChangePassword = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [passwordsMatch, setPasswordsMatch] = useState(true);
  const [errorAlert, setErrorALert] = useState(false);
  const [successAlert, setSuccessAlert] = useState(false);
  const [message, setMessage] = useState("");
//   const { success, error } = useSelector((state) => state.userLogin);

  const handleConfirmPasswordChange = (e) => {
    const confirmPasswordValue = e.target.value;
    setConfirmPassword(confirmPasswordValue);
    setPasswordsMatch(newPassword === confirmPasswordValue);
  };

  const handleNavDashboard = () => {
    // dispatch(setHeaderName("Dashboard"));
    navigate("/dashboard");
  };

  const handleAlertClose = () => {
    setSuccessAlert(false);
    setErrorALert(false);
    setMessage("");
    navigate(-1);
  };

  const handleErrAlertClose = () => {
    setSuccessAlert(false);
    setErrorALert(false);
    setMessage("");
  };

  const handleSubmit = () => {
    const userEntry = {
      oldPassword: oldPassword,
      newPassword: newPassword,
      confirmPassword: confirmPassword,
    };
    // dispatch(pageLoader(true));
    dispatch(changePasswordStudent(userEntry, setSuccessAlert, setErrorALert,setMessage));
  };

  const CloseAlert = () => {
    setSuccessAlert(false);
    setErrorALert(false);
  };

  return (
    <div  >
    <Box width={{xs:"81%",sm:"70%",md:"50%"}} margin={"auto"} mt={7}>
      <Col className={` ${classes.headeingContainer}`}>
        <Typography className={`${classes.SubMainHeading}`}>
          Choose a unique password to Protect your Account
        </Typography>
        <Typography className={classes.someNrmlText}>
          Last change 10 June 2021
        </Typography>
      </Col>
      <Typography className={`mt-2 ${classes.MenuItemText}`}>
        Create a new Password that is 8 Characters Long
      </Typography>
      <ValidatorForm
        onSubmit={handleSubmit}
        useref="form"
        className={`mt-4 ${classes.AddStudentForm}`}
      >
        <Col >
          <div>
            <Typography
              className={`${classes.TableBodyText} ${classes.HeadingTextLable}`}
            >
              Current Password
            </Typography>
            <TextValidator
              sx={{
                "& legend": { display: "none" },
                "& fieldset": { top: 0 },
                marginBottom: "15px",
                position: "relative",
              }}
              id="outlined-adornment-password"
              type={showOldPassword ? "text" : "password"}
              fullWidth
              placeholder="Enter Current Password"
              className={classes.TextInput}
              size="small"
              value={oldPassword}
              onChange={(e) => setOldPassword(e.target.value)}
              validators={["required"]}
              errorMessages={["Current Password is required"]}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setShowOldPassword(!showOldPassword)}
                      edge="end"
                    >
                      {showOldPassword ? <CloseEyeIcon /> : <OpenEyeIcon />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </div>
        </Col>
        <Col >
          <div>
            <Typography
              className={`${classes.TableBodyText} ${classes.HeadingTextLable}`}
            >
              New Password
            </Typography>
            <TextValidator
              sx={{
                "& legend": { display: "none" },
                "& fieldset": { top: 0 },
                marginBottom: "15px",
                position: "relative",
              }}
              id="outlined-adornment-password"
              type={showNewPassword ? "text" : "password"}
              fullWidth
              placeholder="Enter New Password"
              className={classes.TextInput}
              size="small"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              validators={[
                "required",
                "matchRegexp:^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$",
              ]}
              errorMessages={[
                "New Password is required",
                "Your password must minimum of 8 characters, with upper and lowercase and a number or a symbol",
              ]}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setShowNewPassword(!showNewPassword)}
                      edge="end"
                    >
                      {showNewPassword ? <CloseEyeIcon /> : <OpenEyeIcon />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </div>
        </Col>
        <Col >
          <div>
            <Typography
              className={`${classes.TableBodyText} ${classes.HeadingTextLable}`}
            >
              Confirm Password
            </Typography>
            <TextValidator
              sx={{
                "& legend": { display: "none" },
                "& fieldset": { top: 0 },
                marginBottom: "15px",
              }}
              id="outlined-adornment-password"
              type={showConfirmPassword ? "text" : "password"}
              fullWidth
              placeholder="Enter Confirm Password"
              className={classes.TextInput}
              size="small"
              value={confirmPassword}
              onChange={handleConfirmPasswordChange}
              validators={["required"]}
              errorMessages={["Confirm Password is required"]}
              error={!passwordsMatch} // Set an error state if passwords don't match
              helperText={!passwordsMatch && "Passwords do not match"}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() =>
                        setShowConfirmPassword(!showConfirmPassword)
                      }
                      edge="end"
                    >
                      {showConfirmPassword ? <CloseEyeIcon /> : <OpenEyeIcon />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </div>
        </Col>
        <Col  className="mt-3">
          <div className={classes.ChangePasswordBtnSection}>
            <Button
              variant="outlined"
              style={{ color: "var(--forgot-password-text-color)" }}
              onClick={() => navigate(-1)}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              style={{ background: "var(--forgot-password-text-color)" }}
              type="submit"
            >
              Update Password
            </Button>
          </div>
        </Col>
      </ValidatorForm>
      {/* {errorAlert && error && (
        <AlertBox type="error" message={error} stateName={CloseAlert} />
      )} */}
      {successAlert && message && (
        <SuccessAlertBox
          message={message}
          handleAlertClose={handleAlertClose}
        />
      )} 
      {errorAlert && message && (
        <ErrAlertBox
          message={message}
          handleAlertClose={handleErrAlertClose}
        />
      )} 
      </Box>
    </div>
  );
};

export default ChangePassword;
