import React, { useEffect, useState } from "react";
import { Snackbar, Slide } from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function TransitionRight(props) {
  return <Slide {...props} direction="left" />;
}

const ErrAlertBox = ({ message, handleAlertClose }) => {
  useEffect(() => {
    toast.error(message, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      pauseOnHover: false,
      // type: "error",
      theme: "light",
      closeOnClick: true,
      onClose: () => {
        handleAlertClose();
      },
    });
  }, []);

  return (
    <div>
      <ToastContainer />
    </div>
  );
};

export default ErrAlertBox;
