import {
  Box,
  Typography,
  Button,
  Popover,
  MenuItem,
  Badge,
  BottomNavigation,
  BottomNavigationAction,
} from "@mui/material";
import logo from "../Assets/Images/metaMainLogo.png";
import classes from "./layout.module.css";
import rocket from "../Assets/Images/rocket.png";
import bell from "../Assets/Images/bell.png";
import profileLogo from "../Assets/Images/profile-logo.png";
import downArrow from "../Assets/Images/down-arrow.png";
import Notification1 from "../Assets/Images/notification-1.png";
import Notification2 from "../Assets/Images/notification-2.png";
import "../Pages/dashboard/profile.css";
import { StyledMenu } from "../Components/CommonMenu";
import {
  BlueLogoutIcon,
  ChangePasswordIcon,
  Homeicon,
  MyCodeIcon,
  UserIcon,
} from "../Assets/Icons/icons";
import { NavLink, useNavigate, Outlet } from "react-router-dom";
import { useState, useEffect } from "react";
import {
  getAllNotifications,
  getUserByToken,
  updateNotificationStatus,
} from "../Redux/Actions/userActions";
import { useDispatch, useSelector } from "react-redux";
import SuccessModal from "../Components/successModal";
import dayjs from "dayjs";
import { splitFirstLetter } from "../Utilities/commonfunctions";

const dummyNotofication = [
  {
    img: Notification1,
    content: "You have completed Picture Puzzel Game & Recived 50 Diamonds",
    time: "Just Now",
  },
  {
    img: Notification2,
    content: "You have to finish Sequencing Puzzles game for todays Goal",
    time: "30 minutes ago",
  },
  {
    img: Notification1,
    content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do ",
    time: "1 hour ago",
  },
  {
    img: Notification2,
    content: "You have completed Picture Puzzel Game & Recived 50 Diamonds",
    time: "",
  },
];

function ParentLayout() {
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorEl1, setAnchorEl1] = useState(null);
  const [logoutModal, setLogoutModal] = useState(false);
  const [myAllNotifications, setMyAllNotifications] = useState([]);
  const [value, setValue] = useState("Home");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [notifications, setNotifications] = useState(dummyNotofication);
  const { userInfo } = useSelector((state) => state.userLogin);

  const token = JSON.parse(localStorage.getItem("userToken"));
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  console.log("myAllNotifications =", myAllNotifications);
  const handleClick = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event) => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  const handleMenuClick = (event) => {
    setAnchorEl1(event.currentTarget);
  };

  const handleMenuClose = (event) => {
    setAnchorEl1(null);
  };

  const handleClickMyProfile = (e) => {
    handleMenuClose(e);
    navigate("/parent-myprofile");
  };

  const handleClickChangePassword = (e) => {
    handleMenuClose(e);
    navigate("/change-password-parent");
  };

  const handleClickLogout = (e) => {
    handleMenuClose(e);
    setLogoutModal(true);
  };

  const modalClose = () => {
    setLogoutModal(false);
  };

  const handleLogout = () => {
    localStorage.removeItem("userToken");
    navigate("/login");
  };

  const handleGetNotifications = () => {
    dispatch(getAllNotifications(token?.data[0]?.user, setMyAllNotifications));
  };

  const handleMarkRead = () => {
    dispatch(
      updateNotificationStatus(token?.data[0]?.user, handleGetNotifications)
    );
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // useEffect(() => {
  //   if (value === "Home") {
  //     navigate("/parent-dashboard");
  //   } else{
  //     navigate("/parent-user");
  //   }
  // }, [value]);

  useEffect(() => {
    handleGetNotifications();
  }, [window.location.pathname]);

  useEffect(() => {
    if (token && token?.data[0]) {
      dispatch(getUserByToken(navigate, token?.data[0]?._id));
    }
  }, []);
  console.log("userInfo =", userInfo);
  return (
    <>
      <div className={classes.headerlayout}>
        <Box className="myprofile-container">
          <Box width={{ xs: "25%", sm: "18%", md: "10%" }}>
            <img
              src={logo}
              alt="logo"
              width={"100%"}
              onClick={() => navigate("/parent-dashboard")}
            />
          </Box>
          <Box display={{ xs: "none", sm: "block", md: "block" }}>
            <NavLink
              to={"/parent-dashboard"}
              className={({ isActive, isPending }) =>
                isPending ? "pending" : isActive ? "navactive" : "navinactive"
              }
            >
              <Button startIcon={<Homeicon />}> Home</Button>
            </NavLink>
            <NavLink
              to={"/parent-user"}
              className={({ isActive, isPending }) =>
                isPending ? "pending" : isActive ? "navactive" : "navinactive"
              }
            >
              <Button startIcon={<UserIcon />}>User</Button>
            </NavLink>
          </Box>
          <Box display={"flex"} alignItems={"center"} gap={"10px"}>
            <img src={rocket} alt="rocket" className="nav-imgs rocket-icon" />
            <Badge
              badgeContent={myAllNotifications && myAllNotifications?.length}
              className={classes.notify_badge}
              color="primary"
              mx={1}
            >
              <img
                src={bell}
                alt="bell"
                className="nav-imgs"
                onClick={handleClick}
              />
            </Badge>
            <Popover
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
            >
              <Box className="popper-wrapper">
                <Box>
                  <Box className="popper-row-one">
                    <Typography>Notifications </Typography>
                    {Array.isArray(myAllNotifications) &&
                      myAllNotifications?.length > 0 && (
                        <span onClick={handleMarkRead}>Mark all as read</span>
                      )}
                  </Box>
                  <Box className={classes.notification_part}>
                    {Array.isArray(myAllNotifications) &&
                    myAllNotifications?.length > 0 ? (
                      myAllNotifications?.map((data, i) => {
                        return (
                          <Box key={i} className="single-notification">
                            <img src={Notification1} alt="image" />
                            <Box>
                              <Typography fontSize={"14px"}>
                                {data?.message}
                              </Typography>
                              <span>
                                {dayjs(data?.createdAt).format("DD MMMM YYYY")}
                              </span>
                            </Box>
                          </Box>
                        );
                      })
                    ) : (
                      <Typography className={classes.no_notification}>
                        No Notifications
                      </Typography>
                    )}
                  </Box>

                  {/* <Typography className="read-all">
                                Read all notifications
                            </Typography> */}
                </Box>
              </Box>
            </Popover>
            <div>
              <Typography
                className={classes.split_letters_two}
                onClick={handleMenuClick}
              >
                {userInfo?.students &&
                  splitFirstLetter(
                    `${userInfo?.students[0]?.parentInfo?.name}}`
                  )}
              </Typography>
              <img
                src={downArrow}
                alt="downArrow"
                className="nav-imgs"
                style={{ marginLeft: "10px" }}
                onClick={handleMenuClick}
              />
              <StyledMenu
                id="demo-customized-menu"
                MenuListProps={{
                  "aria-labelledby": "demo-customized-button",
                }}
                anchorEl={anchorEl1}
                open={anchorEl1 ? true : false}
                onClose={handleMenuClose}
              >
                <MenuItem
                  onClick={handleClickMyProfile}
                  disableRipple
                  style={{ height: "40px" }}
                >
                  <UserIcon />
                  <span className={classes.MenuItemText}>My Profile</span>
                </MenuItem>
                <MenuItem
                  onClick={handleClickChangePassword}
                  disableRipple
                  style={{ height: "40px" }}
                >
                  <ChangePasswordIcon />
                  <span className={classes.MenuItemText}>Change Password</span>
                </MenuItem>
                <MenuItem
                  onClick={handleClickLogout}
                  disableRipple
                  style={{ height: "40px" }}
                >
                  <BlueLogoutIcon />
                  <span className={classes.MenuItemText}>Logout</span>
                </MenuItem>
              </StyledMenu>
            </div>
          </Box>
        </Box>
        <Box
          className={classes.bottom_nav}
          display={{ xs: "block", sm: "none", md: "none" }}
        >
          <BottomNavigation
            sx={{ width: "100%" }}
            value={value}
            onChange={handleChange}
          >
            <BottomNavigationAction
              label="Home"
              value="Home"
              icon={<Homeicon />}
              onClick={() => navigate("/parent-dashboard")}
            />
            <BottomNavigationAction
              label="User"
              value="User"
              icon={<UserIcon />}
              onClick={() => navigate("/parent-user")}
            />
          </BottomNavigation>
        </Box>
        {logoutModal && (
          <SuccessModal
            modalClose={modalClose}
            width={335}
            height={153}
            type={"logout"}
            callback={handleLogout}
          />
        )}
        <Outlet />
      </div>
    </>
  );
}

export default ParentLayout;
