import { Box, Button, Container, Grid, Typography } from "@mui/material";
import classes from './myprofile.module.css'
import Avatar from "../../Assets/Images/new-avatar.png";
import InventoryOne from "../../Assets/Images/inventory-1.png";
import InventoryTwo from "../../Assets/Images/inventory-2.png";
import InventoryThree from "../../Assets/Images/inventory-3.png";
import InventoryFour from "../../Assets/Images/inventory-4.png";
import InactiveImg from "../../Assets/Images/bottom-img.png";
import Star from "../../Assets/Images/level-star.png";
import Ballon from "../../Assets/Images/game-ballon.png";
import { DiamondIcon, LockIcon } from "../../Assets/Icons/icons";
import { useState,useEffect } from "react";

const dummyInventory = [
    {
        id: 1,
        img: InventoryOne,
        diamond: 122,
        status: true,
        bgcolor:"#FF942A"
    },
    {
        id: 2,
        img: InventoryTwo,
        diamond: 152,
        status: true,
        bgcolor:"#6AD7E0"
    },
    {
        id: 3,
        img: InventoryThree,
        diamond: 166,
        status: true,
        bgcolor:"#FFADDB"
    },
    {
        id: 4,
        img: InventoryFour,
        diamond: 193,
        status: true,
        bgcolor:"#BA9BFF"
    },
    {
        id: 5,
        img: InventoryThree,
        diamond: 122,
        status: false
    },
    {
        id: 6,
        img: InventoryOne,
        diamond: 122,
        status: false
    },
    {
        id: 7,
        img: InventoryOne,
        diamond: 122,
        status: false
    },
    {
        id: 8,
        img: InventoryOne,
        diamond: 122,
        status: false
    }
]

function Inventory() {
    const [myinventory, setMyinventory] = useState(dummyInventory);

    useEffect(() => {
        window.scroll({
          top:0,
          behavior:"smooth"
        })
      
      }, [])
    return (<Box position={"relative"} >
        <Container sx={{ paddingBottom: "15px" }} >
            <Typography className={classes.main_title} >
                Inventory
            </Typography>
            <Grid container>
                <Grid item xs={12} sm={12} md={3}>
                    <Box textAlign={"center"} >
                        <img src={Avatar} alt="avatar" />
                        <Typography className={classes.inventory_name} >
                            Joe
                        </Typography>
                        <Box className={classes.inventory_level}>
                            <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"}  >
                                <Typography>Lv.01</Typography>
                                <Typography>25 <DiamondIcon /> </Typography>
                            </Box>
                            <Box bgcolor={"#FFF1EB"} borderRadius={"6px"} height={"10px"} mt={2} >
                                <Typography borderRadius={"6px"} bgcolor={"#854DFF"} width={"65%"} height={"100%"}></Typography>
                            </Box>
                        </Box>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={1}></Grid>
                <Grid item xs={12} sm={12} md={8}>
                    <Box>
                        <Grid container spacing={2} >
                           { myinventory.map((data,i)=>{
                            return (
                                data.status ?
                                <Grid key={i} item xs={6} sm={6} md={3}>
                            <Box className={classes.single_points} >
                                <img src={data.img} alt="image" width={"100px"} />
                                <Typography className={classes.n_diamond} bgcolor={data.bgcolor} >
                                    {data.diamond} <DiamondIcon />
                                </Typography>
                                <Button>
                                    Redeem
                                </Button>
                            </Box>
                        </Grid> :
                        <Grid key={i} item xs={6} sm={6} md={3}>
                        <Box className={classes.single_points_locked} >
                            <img src={InactiveImg} alt="image" />
                            <Box>
                                <Typography>
                                    Unlock when <br /> you reach <br /> <b>Level 5</b>
                                </Typography>
                            </Box>
                            <LockIcon />
                        </Box>
                    </Grid>
                            )
                           }) }
                        </Grid>
                        {/* <Box className={classes.single_points_locked} >
                            <img src={InactiveImg} alt="image" />
                            <Box>
                                <Typography>
                                    Unlock when <br /> you reach <br /> <b>Level 5</b>
                                </Typography>
                            </Box>
                            <LockIcon />
                        </Box> */}
                    </Box>

                </Grid>
            </Grid>
            <img src={Star} alt="image" className={classes.inv_star}  />
            <img src={Ballon} alt="image" className={classes.inv_ballon}  />
        </Container>
    </Box>)
};

export default Inventory;