import React, { useEffect,useState } from "react";
import classes from "./layout.module.css";
import { Outlet, useNavigate } from "react-router-dom";
import Navbar from "./Navbar";
import { useDispatch, useSelector } from "react-redux";
import AlertBox from "../Components/AlertBox";
import { getUserByToken } from "../Redux/Actions/userActions";
import { BottomNavigation, BottomNavigationAction, Box } from "@mui/material";
import { Homeicon, MyCodeIcon } from "../Assets/Icons/icons";

const DefaultLayout = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [value, setValue] = useState('Home');
  const { errorMessage, errorAlert, successMessage, successAlert } =
    useSelector((state) => state.userLogin);
  const { loading, toggleSideBar, userInfo, error } = useSelector(
    (state) => state.userLogin
  );
  const token = JSON.parse(localStorage.getItem("userToken"));

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const closeAlert = () => {
    dispatch({
      type: "USER_LOGIN_REQUEST_FAIL",
    });
  };

  //   useEffect(() => {
  //   if (value === "Home") {
  //     navigate("/home");
  //   } else{
  //     navigate("/mycode");
  //   }
  // }, [value]);

  useEffect(() => {
    console.log("token", token);
    if (token) {
      dispatch(getUserByToken(navigate,token?.data[0]?._id));
    }
  }, []);

  useEffect(() => {
    console.log("userInfo =",userInfo)
    if (
      userInfo &&
      userInfo?.customerSubscriptionCount === 0 &&
      !window.location.pathname.includes("payment") &&
      !window.location.pathname.includes("subscription") &&
      userInfo?.studentInfo?.gst_type !== "B2C"
    ) {
      navigate("/myprofile");
    }
  }, [userInfo, window.location.pathname]);

  return (
    <div className={classes.headerlayout}>
      <Navbar />
      <Outlet />
     <Box className={classes.bottom_nav} display={{xs:"block",sm:"none",md:"none"}}>
     <BottomNavigation sx={{ width: "100%" }}  value={value} onChange={handleChange}>
      <BottomNavigationAction
        label="Home"
        value="Home"
        icon={<Homeicon />}
        onClick={()=>navigate("/home")}
      />
      <BottomNavigationAction
        label="My Code"
        value="My Code"
        icon={<MyCodeIcon />}
        onClick={()=>navigate("/mycode")}
      />
    </BottomNavigation>
     </Box>
      {errorMessage && errorAlert && (
        <AlertBox
          message={errorMessage}
          type="error"
          onCloseFunction={closeAlert}
        />
      )}
      {successMessage && successAlert && (
        <AlertBox
          message={successMessage}
          type="sucsess"
          onCloseFunction={closeAlert}
        />
      )}
    </div>
  );
};

export default DefaultLayout;
