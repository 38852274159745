import { Box, Container, Grid, Typography, Button } from "@mui/material";
import "./profile.css";
import Elephant from "../../Assets/Images/elephant.png";
import Elephant2 from "../../Assets/Images/elephant2.png";
import Elephant3 from "../../Assets/Images/elephant3.png";
import { useEffect, useState } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { useDispatch, useSelector } from "react-redux";
import { myCodeCourseProgress } from "../../Redux/Actions/userActions";
import { useNavigate } from "react-router-dom";

const dummyAch = [
  {
    id: 1,
    img: Elephant,
  },
  {
    id: 2,
    img: Elephant2,
  },
  {
    id: 3,
    img: Elephant3,
  },
];

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 3,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1024, min: 850 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 850, min: 0 },
    items: 1,
  },
};

function MyCode() {
  const [myCodeGames, setMyCodeGames] = useState({
    playedGames: [],
    unplayedGames: [],
  });
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { userInfo } = useSelector((state) => state.userLogin);
  let subscription_status = userInfo?.customerSubscriptionCount > 0;

  function handleGetMyCode() {
    let _token = JSON.parse(localStorage.getItem("userToken"));
    let studentId = userInfo?.studentInfo?._id || _token?.data[0]?._id;
    dispatch(
      myCodeCourseProgress(studentId, _token?.accessToken, setMyCodeGames)
    );
  }

  useEffect(() => {
    if (subscription_status) {
      handleGetMyCode();
    }
    window.scrollTo({
        top:0,
        behavior:"smooth"        
    })
  }, [userInfo]);

  console.log(myCodeGames);
  return (
    <>
      <Box className="dashboard-mycode">
        <Container>
          <Box>
            <Typography className="mycode-title">My Achievements</Typography>
            <Box className="achievments-slider">
              <Carousel
                responsive={responsive}
                autoPlaySpeed={1500}
                infinite={true}
                arrows={true}
              >
                {myCodeGames?.playedGames.map((item, index) => {
                  return (
                    <Box height={"210px"} width={"86%"} margin={"auto"}>
                      <Box className="achievement-box">
                        <Typography className="achievement-title">
                          {item.game_name}
                        </Typography>
                        <Typography className="achievement-sub-title">
                        No. of Level  : <b>{item.totalLevels}</b>
                        </Typography>
                        <Button
                          className="btn-one mt-4"
                          onClick={() =>
                            navigate("/levels", {
                              state: { singleGameDetail: item },
                            })
                          }
                        >
                        {item.progressPercentage == 100 ?"Completed":"Resume" }
                        </Button>
                        <Typography className="achievement-percent">
                          {item.progressPercentage}% Completed
                        </Typography>
                        <img
                          src={`${process.env.REACT_APP_API_URL}/${item.cover_image}`}
                          alt="elephant"
                          width={"120px"}
                        />
                      </Box>
                      <Box
                        width={"100%"}
                        height={"3%"}
                        position={"relative"}
                        bottom={"0px"}
                        bgcolor={"#FFF1EB"}
                        borderRadius={"20px"}
                        overflow={"hidden"}
                      >
                        <Typography
                          height={"100%"}
                          width={`${item.progressPercentage}%`}
                          bgcolor={"#FF942A"}
                          borderRadius={"20px"}
                        ></Typography>
                      </Box>
                    </Box>
                  );
                })}
              </Carousel>
              {/* <Box className="achievement-box" >
                                <Typography className="achievement-title">Picture puzzles</Typography>
                                <Typography className="achievement-sub-title">Total Amount</Typography>
                                <Button className="btn-one mt-4"  >Resume</Button>
                                <img src={Elephant2} alt="elephant" />
                            </Box> */}
            </Box>
          </Box>
          <Box mt={3}>
            <Typography className="mycode-title">
              Recommended Games ({myCodeGames?.unplayedGames.length})
            </Typography>
            <Box className="achievments-slider">
              <Carousel
                responsive={responsive}
                autoPlaySpeed={1500}
                infinite={true}
                arrows={true}
              >
                {myCodeGames?.unplayedGames.map((item, index) => {
                  return (
                    <Box height={"210px"} width={"86%"} margin={"auto"}>
                      <Box className="achievement-box">
                        <Typography className="achievement-title">
                          {item.game_name}
                        </Typography>
                        <Typography className="achievement-sub-title">
                        No. of Level  : <b>{item.totalLevels}</b>
                        </Typography>
                        <Button
                          className="btn-one mt-5"
                          onClick={() =>
                            navigate("/levels", {
                              state: { singleGameDetail: item },
                            })
                          }
                        >
                          Play
                        </Button>
                        <img
                          src={`${process.env.REACT_APP_API_URL}/${item.cover_image}`}
                          alt="elephant"
                          width={"120px"}
                        />
                      </Box>
                    </Box>
                  );
                })}
              </Carousel>
            </Box>
            {/* <Box display={"flex"} mt={2}>
              <Box className="achievement-box recommended-box">
                <Typography className="achievement-title">
                  Picture puzzles
                </Typography>
                <Typography className="achievement-sub-title">
                  Total Amount
                </Typography>
                <Button className="btn-one mt-4">Play</Button>
                <img src={Elephant2} alt="elephant" />
              </Box>
            </Box> */}
          </Box>
        </Container>
      </Box>
    </>
  );
}

export default MyCode;
