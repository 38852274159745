module.exports = {
    GET_SUBSCRIPTION_SUCCESS: "GET_SUBSCRIPTION_SUCCESS",
    GET_SUBSCRIPTION_FAIL: "GET_SUBSCRIPTION_FAIL",
    GET_ORDER_DETAILS_SUCCESS: "GET_ORDER_DETAILS_SUCCESS",
    GET_ORDER_DETAILS_FAIL: "GET_ORDER_DETAILS_FAIL",
    GET_CUSTOMER_SUBSCRIPTION_LIST_SUCCESS: "GET_CUSTOMER_SUBSCRIPTION_LIST_SUCCESS",
    GET_CUSTOMER_SUBSCRIPTION_LIST_FAIL: "GET_CUSTOMER_SUBSCRIPTION_LIST_FAIL",
    GENERATE_ORDER_SUCCESS: "GENERATE_ORDER_SUCCESS",
    GENERATE_ORDER_FAIL: "GENERATE_ORDER_FAIL",
    DOWNLOAD_INVOICE_SUCCESS: "DOWNLOAD_INVOICE_SUCCESS",
    DOWNLOAD_INVOICE_FAIL: "DOWNLOAD_INVOICE_FAIL",
    PAYMENT_SUCCESS: "PAYMENT_SUCCESS",
    PAYMENT_FAIL: "PAYMENT_FAIL"
}