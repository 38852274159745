import './profile.css';
import WelcomeImg from "../../Assets/Images/welcome-img.png";
import { Box, Container, Grid, Typography, Button } from "@mui/material";

function BottomContent() {
    return (<>
        <Box className="bottom-content" >
            <img src={WelcomeImg} alt="games" />
            <Typography>
                It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.
            </Typography>
        </Box>
    </>)
}

export default BottomContent;