import React, { useState, useEffect } from "react";
import {
  Card,
  Typography,
  IconButton,
  InputAdornment,
  Button,
  Divider,
} from "@mui/material";
import classes from "./auth.module.css";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { CloseEyeIcon, OpenEyeIcon } from "../../Assets/Icons/icons";
import faceBookBtn from "../../Assets/Images/faceBookBtn.png";
import googleBtn from "../../Assets/Images/googleBtn.png";
import { useDispatch } from "react-redux";
import { forgotPassword } from "../../Redux/Actions/userActions";
import { useLocation, useNavigate } from "react-router-dom";

const ForgetPassword = () => {
  const [input, setInput] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const handleSubmit = () => {
    let title;
    if (
      /\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+.[A-Za-z]{2,}\b/.test(input)
    ) {
      title = "email";
      // setTitle(title);
    } else {
      title = "phone";
      // setTitle(title);
    }
    let body = {
      username: input,
      title: title
    };
    dispatch(forgotPassword(body, navigate));
  };
  console.log(location?.state?.details,'41');
  useEffect(()=>{
    setInput(location?.state?.details);
  },[])
  return (
    <div className={classes.LoginMainDiv}>
      <Card className={classes.LoginMainCard} sx={{ paddingBottom: "0px", boxShadow: "0 0 35px rgba(0, 0, 0, 0.2)" }} >
        <Typography variant="h2" className={classes.authHeader}>
          Reset Password
        </Typography>
        <Typography
          variant="h6"
          className={`mt-2 ${classes.authHeaderSubText}`}
        >
          Please enter your Email ID / Mobile Number to reset
        </Typography>
        <ValidatorForm
          useref="form"
          onSubmit={handleSubmit}
          className={`mt-5 mb-4 ${classes.formInputContainer}`}
        >
          <div className={`mt-4`}>
            <TextValidator
              id="emailInput"
              fullWidth
              placeholder="Email ID / Mobile Number"
              className={`${classes.TextInput}`}
              value={input}
              type={"text"}
              size="small"
              focused
              onChange={(e) => {
                setInput(e.target.value);
              }}
              validators={["required"]} // Add this line
              errorMessages={["Email ID / Mobile Number is required"]}
            />
          </div>
          <div className={`mt-3 ${classes.loginSubmitBtnContainer}`}>
            <Button
              variant="contained"
              className={`${classes.loginSubmitBtn}`}
              type="submit"
              sx={{ marginTop: "10px" }}
            >
              Get Secure Code
            </Button>
          </div>
        </ValidatorForm>

      </Card>
    </div>
  );
};

export default ForgetPassword;
