import { Box, Button, Container, Grid, Typography } from "@mui/material";
import classes from "./myprofile.module.css";
import Ellipse from "../../Assets/Images/Ellipse5.png";
import Star from "../../Assets/Images/star.png";
import ProfileLogo from "../../Assets/Images/profile-logo.png";
import Diamond from "../../Assets/Images/diamond.png";
import Subscription from "../../Assets/Images/subscription.png";
import BlockCoding from "../../Assets/Images/block-coding.png";
import PythonCoding from "../../Assets/Images/python-coding.png";
import AchievmentCup from "../../Assets/Images/achievment-cup.png";
import AchievmentOne from "../../Assets/Images/achievment-one.png";
import AchievmentTwo from "../../Assets/Images/achievment-two.png";
import Avatar from "../../Assets/Images/Avatar.png";
import Avatar1 from "../../Assets/Images/Avatar1.png";
import Avatar2 from "../../Assets/Images/Avatar2.png";
import Nodata from "../../Assets/Images/nodata.png";
import { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import "./myprofile.css";
import { useSelector, useDispatch } from "react-redux";
import {
  getFriendList,
  getStudentAcheivementsList,
  getUserByToken,
  setSelectedFriend,
} from "../../Redux/Actions/userActions";
import { splitFirstLetter } from "../../Utilities/commonfunctions";
import dayjs from "dayjs";
import { GenerateOrder } from "../../Redux/Actions/subscriptionAction";

const dummyAchievments = [
  {
    id: 1,
    img: AchievmentOne,
    title: "Computer Programming 2",
    sub_title: "Acheived On Jan 2022",
  },
  {
    id: 2,
    img: AchievmentTwo,
    title: "Information Technology",
    sub_title: "Acheived On Jan 2022",
  },
  {
    id: 3,
    img: AchievmentOne,
    title: "Computer Programming 2",
    sub_title: "Acheived On Jan 2022",
  },
];

const dummyFriends = [
  {
    id: 1,
    img: Avatar,
    status: true,
  },
  {
    id: 5,
    img: Avatar1,
    status: false,
  },
  {
    id: 3,
    img: Avatar2,
    status: false,
  },
  {
    id: 4,
    img: Avatar,
    status: false,
  },
];

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 3,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1024, min: 850 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 850, min: 0 },
    items: 1,
  },
};

function MyProfileHome() {
  const { userInfo, userRole, error, loggedUser } = useSelector(
    (state) => state.userLogin
  );
  const [achievments, setAchievements] = useState(dummyAchievments);
  const [currentItem, setCurrentItem] = useState(null);
  const [friendList, setFriendList] = useState([]);
  const [achievementsList, setAchievementsList] = useState([]);
  const [errorAlert, setErrorAlert] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const carouselRef = useRef(null);
  let _token = JSON.parse(localStorage.getItem("userToken"));
  let subscription_status = userInfo?.customerSubscriptionCount > 0;

  const handleSlideChange = () => {
    const centerItemIndex = Math.floor(
      carouselRef.current.state.currentSlide +
        carouselRef.current.state.slidesToShow / 2
    );
    setCurrentItem(centerItemIndex - 6);
  };

  const handleFriendList = () => {
    dispatch(getFriendList(setFriendList));
  };

  const handleSubmit = (_id) => {
    const data = {
      Id: _id,
    };
    dispatch(
      setSelectedFriend(userInfo?.studentInfo?._id, data, navigate, "myprofile")
    );
    dispatch(getUserByToken(navigate, _token?.data[0]?._id));
  };

  const handleCheckUser = () => {
    if (!subscription_status && _token?.data[0]?.gst_type === "B2C") {
      navigate("/select-subscription");
    } else if (!subscription_status && _token?.data[0]?.gst_type === "B2B") {
      navigate("/payment-details");
    }
  };

  const handleRenewUser = () => {
    console.log("userInfo =",userInfo)
    const userEntry = {
      user_id: userInfo?._id,
      subscription_id: userInfo?.subscriptionInfo?._id,
      subscribed_by: "INDIVIDUAL",
      type: "renew",
      student_id: userInfo?.studentInfo?._id,
    };
    dispatch(GenerateOrder(userEntry, setErrorAlert, navigate));
    // if (_token?.data[0]?.gst_type === "B2C") {
    //   navigate("/select-subscription");
    // } else if (_token?.data[0]?.gst_type === "B2B") {
    //   navigate("/payment-details");
    // }
  };

  const handleCheckUserTwo = () => {
    if (subscription_status) {
      navigate("/inventory");
    } else if (!subscription_status && _token?.data[0]?.gst_type === "B2C") {
      navigate("/select-subscription");
    } else if (!subscription_status && _token?.data[0]?.gst_type === "B2B") {
      navigate("/payment-details");
    }
  };

  function handleGetAcheivements() {
    let single_student = userInfo?.studentInfo?._id;

    if (single_student) {
      dispatch(getStudentAcheivementsList(single_student, setAchievementsList));
    }
  }

  function handleAchImg(index) {
    if (index % 2 == 0) {
      return AchievmentOne;
    } else {
      return AchievmentTwo;
    }
  }

  useEffect(() => {
    if (userInfo) {
      handleGetAcheivements();
    }
  }, [userInfo]);

  useEffect(() => {
    handleFriendList();
  }, []);

  useEffect(() => {
    window.scroll({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  console.log("userInfo =", userInfo);
  return (
    <>
      <Container sx={{ paddingBottom: "15px" }}>
        <Typography className={classes.main_title}>My Profile</Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={9}>
            <Box className={classes.profile_details}>
              <Grid container>
                <Grid item xs={12} sm={13} md={5}>
                  <Box
                    display={"flex"}
                    alignItems={"center"}
                    gap={"15px"}
                    height={"100%"}
                  >
                    {userInfo?.avatar_url ? (
                      <img
                        src={`https://api.meta.apps.org.in/api/${userInfo?.avatar_url}`}
                        width={"100px"}
                        className={classes.avatar_url}
                        onClick={() => navigate("/updateavatar")}
                      />
                    ) : (
                      <Typography
                        className={classes.split_letters}
                        onClick={() => navigate("/updateavatar")}
                      >
                        {splitFirstLetter(
                          `${userInfo?.studentInfo?.first_name} ${userInfo?.studentInfo?.last_name}`
                        )}
                      </Typography>
                    )}
                    <Box>
                      <Typography className={classes.profile_name}>
                        {userInfo?.studentInfo?.first_name}{" "}
                        {userInfo?.studentInfo?.last_name}
                      </Typography>
                      <Box display={"flex"} gap={"15px"}>
                        <Typography className={classes.label}>
                          Age:{" "}
                          <span className={classes.text}>
                            {" "}
                            {userInfo?.studentInfo?.age} Years
                          </span>{" "}
                        </Typography>
                        <Typography className={classes.label}>
                          Grade:{" "}
                          <span className={classes.text}>
                            {userInfo?.grade_name?.split("_")[1]}
                          </span>{" "}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <Grid container>
                    <Grid item xs={12} sm={13} md={6}>
                      <Typography className={classes.label} my={1}>
                        {userInfo?.studentInfo?.gst_type === "B2B"
                          ? "School"
                          : "Certified By"}
                        :{" "}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={13} md={6}>
                      <Typography className={classes.text} my={1}>
                        {userInfo?.schoolInfo?.name}{" "}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={13} md={6}>
                      <Typography className={classes.label} my={1}>
                        {userInfo?.studentInfo?.gst_type === "B2B"
                          ? "School Phone Number"
                          : "Support Number"}
                        :
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={13} md={6}>
                      <Typography className={classes.text} my={1}>
                        {userInfo?.schoolInfo?.phone}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={13} md={6}>
                      <Typography className={classes.label} my={1}>
                        {userInfo?.studentInfo?.gst_type === "B2B"
                          ? "School Email"
                          : "Support Email"}
                        :
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={13} md={6}>
                      <Typography className={classes.text} my={1}>
                        {userInfo?.schoolInfo?.email}{" "}
                      </Typography>
                    </Grid>
                    {userInfo?.studentInfo?.gst_type === "B2B" && (
                      <>
                        <Grid item xs={12} sm={13} md={6}>
                          <Typography className={classes.label} my={1}>
                            Class Teacher:
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={13} md={6}>
                          <Typography className={classes.text} my={1}>
                            {userInfo?.schoolInfo?.teacher_name ?? "n/a"}{" "}
                          </Typography>
                        </Grid>
                      </>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={3}>
            <Box
              className={classes.myprogress}
              mb={3}
              // onClick={() => navigate("/progress")}
            >
              <Typography className={classes.myprogress_title}>
                My Progress/Badges
              </Typography>
              <Box
                display={"flex"}
                alignItems={"end"}
                justifyContent={"space-between"}
                mt={1}
              >
                {userInfo?.avatar_url ? (
                  <img
                    src={`https://api.meta.apps.org.in/api/${userInfo?.avatar_url}`}
                    className={classes.avatar_url}
                    style={{ width: "50px", border: "5px solid #854dff" }}
                    onClick={() => navigate("/updateavatar")}
                  />
                ) : (
                  <Typography
                    className={classes.split_letters_two}
                    onClick={() => navigate("/updateavatar")}
                  >
                    {splitFirstLetter(
                      `${userInfo?.studentInfo?.first_name} ${userInfo?.studentInfo?.last_name}`
                    )}
                  </Typography>
                )}
                <Box
                  display={"flex"}
                  flexDirection={"column"}
                  gap={"10px"}
                  alignItems={"end"}
                  justifyContent={"space-between"}
                >
                  <img src={Star} alt="elephant" />
                </Box>
              </Box>
              <Box
                height={"12px"}
                bgcolor={"#557CCB"}
                borderRadius={"20px"}
                mt={2}
              >
                <Typography
                  height={"100%"}
                  width={`${userInfo?.progress}%`}
                  bgcolor={"#FFCB00"}
                  borderRadius={"20px"}
                ></Typography>
              </Box>
              <Box
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"center"}
                mt={2}
              >
                <Typography className={classes.progress_level}>
                  {userInfo?.progress}% Progress
                </Typography>
                <Typography className={classes.progress_level}>
                  Level {userInfo?.studentInfo?.level}
                </Typography>
              </Box>
            </Box>
          </Grid>
        </Grid>
        <Grid container spacing={2} mt={1}>
          <Grid item xs={12} sm={12} md={6}>
            <Box className={classes.select_friend_wrapper}>
              <Typography className={classes.sub_title} mb={2}>
                Select Friend
              </Typography>
              <Box className="select-friend">
                <Box className={classes.swipper_container}>
                  <Carousel
                    ref={carouselRef}
                    responsive={responsive}
                    autoPlaySpeed={1500}
                    infinite={true}
                    arrows={true}
                    afterChange={handleSlideChange}
                  >
                    {Array.isArray(friendList) &&
                      friendList.map((datas, index) => {
                        return (
                          <Box
                            display={"flex"}
                            flexDirection={"column"}
                            alignItems={"center"}
                            justifyContent={"center"}
                          >
                            <img
                              src={`https://api.meta.apps.org.in/api/${datas?.image}`}
                              alt="start"
                              width={"70%"}
                            />
                            {userInfo?.friend_id ? (
                              userInfo?.friend_id !== datas._id && (
                                <Typography
                                  mt={3}
                                  className={classes.bottom_title}
                                  onClick={() => handleSubmit(datas._id)}
                                >
                                  Select
                                </Typography>
                              )
                            ) : (
                              <Typography
                                mt={3}
                                className={classes.bottom_title}
                                onClick={() => handleSubmit(datas._id)}
                              >
                                Select
                              </Typography>
                            )}
                          </Box>
                        );
                      })}
                  </Carousel>
                </Box>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={3}>
            <Box className={classes.mydiamonds}>
              <Box>
                <Typography fontSize={"24px"}>Diamonds</Typography>
                <Typography fontSize={"40px"}>
                  {userInfo?.studentInfo?.diamonds}
                </Typography>
              </Box>
              <img src={Diamond} alt="elephant" />
              <Box>
                {/* <Button
                  className={classes.cardbutton}
                  onClick={handleCheckUserTwo}
                >
                  Buy Gifts
                </Button> */}
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={3}>
            <Box className={classes.subscription}>
              <Box>
                <Typography fontSize={"24px"}>Subscription Info</Typography>
                <Typography
                  fontSize={"40px"}
                  visibility={subscription_status ? "visible" : "hidden"}
                >
                  {userInfo?.subscriptionInfo?.subscription_name}
                </Typography>
              </Box>
              <img src={Subscription} alt="elephant" />
              {userInfo?.customerSubscriptionCount === 0 ? (
                <Box>
                  <Button
                    className={classes.cardbutton}
                    onClick={handleCheckUser}
                  >
                    Active Plan
                  </Button>
                </Box>
              ) : (
                <Box>
                  <Button
                    className={classes.renewbutton}
                    onClick={handleRenewUser}
                  >
                    Renew
                  </Button>
                  <Typography className={classes.subscription_date}>
                    End Date :{" "}
                    <span>
                      {dayjs(userInfo?.subscriptionInfo?.end_date).format(
                        "DD MMMM YYYY"
                      )}
                    </span>
                  </Typography>
                </Box>
              )}
            </Box>
          </Grid>
        </Grid>
        <Grid container spacing={2} mt={1}>
          <Grid item xs={6} sm={6} md={3}>
            <Box className={classes.completed_game}>
              <Typography className={classes.sub_title} mb={2}>
                Completed Game
              </Typography>
              <Box>
                <Typography>
                  {userInfo?.completedGames ? userInfo?.completedGames : 0}
                </Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={6} sm={6} md={3}>
            <Box className={classes.int_sec}>
              <Typography className={classes.sub_title} mb={2}>
                Interested Section
              </Typography>
              <Box
                className={classes.coding}
                bgcolor={
                  userInfo?.studentInfo?.interest?.toLowerCase() ===
                    "block_coding" && "#98E9F0"
                }
              >
                <img src={BlockCoding} alt="block-coding" />
                <Typography mt={1}>Block Coding</Typography>
              </Box>
              <Box
                className={classes.coding}
                bgcolor={
                  userInfo?.studentInfo?.interest?.toLowerCase() ===
                    "python_coding" && "#98E9F0"
                }
                mt={2}
              >
                <img src={PythonCoding} alt="block-coding" />
                <Typography>Python Coding</Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <Box className={classes.myachievments}>
              <Typography className={classes.sub_title} mb={2}>
                My Achievements
              </Typography>
              <span>Look below what you have achieved !</span>
              <Box mt={3} minHeight={"180px"}>
                {Array.isArray(achievementsList) &&
                achievementsList?.length > 0 ? (
                  achievementsList?.slice(0, 2).map((values, index) => {
                    return (
                      <Box className={classes.single_achiev}>
                        <img src={handleAchImg(index)} alt="achievments" />
                        <Box>
                          <Typography>
                            {values.topic?.replace("_", " ")}
                          </Typography>
                          <span>
                            Achieved On{" "}
                            {dayjs(values.createdAt).format("MMMM YYYY")}
                          </span>
                        </Box>
                      </Box>
                    );
                  })
                ) : (
                  <Box
                    display={"flex"}
                    justifyContent={"center"}
                    flexDirection={"column"}
                    alignItems={"center"}
                    height={"100%"}
                  >
                    <img src={Nodata} width={"150px"} />
                    <Typography sx={{ color: "#5D6892" }}>
                      No Achievements
                    </Typography>
                  </Box>
                )}
              </Box>
              {/* <Link to={"/myachievements"}> */}
              {achievementsList?.length > 0 && (
                <Typography
                  className={classes.bottom_title}
                  onClick={() =>
                    navigate("/myachievements", { state: { achievementsList } })
                  }
                >
                  View All
                </Typography>
              )}
              {/* </Link> */}
              <img
                src={AchievmentCup}
                alt="achievments"
                className={classes.achievment_cup}
              />
            </Box>
          </Grid>
        </Grid>
        <Box className={classes.parent_info}>
          <Typography className={classes.parent_info_title}>
            Parents Information
          </Typography>
          <Grid container>
            <Grid item xs={12} sm={12} md={4}>
              <Grid container>
                <Grid item xs={12} sm={12} md={6}>
                  <Typography className={classes.label}>
                    Mobile Number
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <Typography className={classes.text}>
                    {userInfo?.parentInfo?.phone}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <Typography className={classes.label}>Email ID</Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <Typography className={classes.text}>
                    {userInfo?.parentInfo?.email}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={8}>
              <Box textAlign={"end"}>{/* <Button>Change</Button> */}</Box>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </>
  );
}

export default MyProfileHome;
