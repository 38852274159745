import React, { useRef } from "react";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import {
  Box,
  Container,
  Grid,
  Typography,
  Button,
  Tabs,
  Tab,
} from "@mui/material";
import classes from "./parent.module.css";
import classes1 from "../myprofile/myprofile.module.css";
import Ellipse from "../../Assets/Images/Ellipse2.png";
import Diamond from "../../Assets/Images/diamond.png";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Star from "../../Assets/Images/star.png";
import AchievmentCup from "../../Assets/Images/achievment-cup.png";
import BlockCoding from "../../Assets/Images/block-coding.png";
import PythonCoding from "../../Assets/Images/python-coding.png";
import AchievmentOne from "../../Assets/Images/achievment-one.png";
import AchievmentTwo from "../../Assets/Images/achievment-two.png";
import Topic1 from "../../Assets/Images/topic1.png";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { splitFirstLetter } from "../../Utilities/commonfunctions";
import PropTypes from "prop-types";
import { DownloadIcon } from "../../Assets/Icons/icons";
import { getStudentAcheivementsList } from "../../Redux/Actions/userActions";
import Nodata from "../../Assets/Images/nodata.png";
import dayjs from "dayjs";

const dummyAchievments = [
  {
    id: 1,
    img: AchievmentOne,
    title: "Computer Programming 2",
    sub_title: "Acheived On Jan 2022",
  },
  {
    id: 2,
    img: AchievmentTwo,
    title: "Information Technology",
    sub_title: "Acheived On Jan 2022",
  },
  {
    id: 3,
    img: AchievmentOne,
    title: "Computer Programming 2",
    sub_title: "Acheived On Jan 2022",
  },
];

const users = ["User One", "User Two"];

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ py: 3, px: 1 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function ParentUser() {
  const [achievments, setAchievements] = useState(dummyAchievments);
  const [tabValue, setTabValue] = useState(0);
  const [achievementsList, setAchievementsList] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const { otp_data, userInfo } = useSelector((state) => state.userLogin);
  const printRef = useRef([]);

  const handleExportAsPDF = async () => {
    console.log(printRef.current);
    const element = printRef.current;
    const canvas = await html2canvas(element);
    const data = canvas.toDataURL("image/png");

    const pdf = new jsPDF();
    const imgProperties = pdf.getImageProperties(data);
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = (imgProperties.height * pdfWidth) / imgProperties.width;

    pdf.addImage(data, "PNG", 0, 0, pdfWidth, pdfHeight);
    pdf.save("download.pdf");
  };

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  function handleGetAcheivements() {
    let single_student = userInfo?.students[tabValue];
    if (single_student) {
      dispatch(
        getStudentAcheivementsList(single_student?._id, setAchievementsList)
      );
    }
  }

  function handleAchImg(index) {
    if (index % 2 === 0) {
      return AchievmentOne;
    } else {
      return AchievmentTwo;
    }
  }

  useEffect(() => {
    if (userInfo) {
      handleGetAcheivements();
    }
  }, [userInfo, tabValue]);

  useEffect(() => {
    if (location?.state?.userTab) {
      setTabValue(location?.state?.userTab);
    } else {
      setTabValue(0);
    }

    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <>
      <Box my={3}>
        <Container>
        <Box display={{xs:"flex",sm:"flex",md:"none"}} alignItems={"center"} gap={"30px"}>
              <Typography
                className={classes.download_btn}
                onClick={handleExportAsPDF}
              >
                <DownloadIcon /> Download Report
              </Typography>
              <Link to={"/parent-dashboard"}>
                <Button className={classes.view_more}>Add User</Button>
              </Link>
            </Box>
          <Box
            display={"flex"}
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <Box className="my_switch_tabs" display={"inline-block"}>
              <Tabs
                value={tabValue}
                onChange={handleChange}
                aria-label="basic tabs example"
              >
                {Array.isArray(userInfo?.students) &&
                  userInfo?.students?.map((datas, i) => {
                    return <Tab label={datas?.first_name} {...a11yProps(i)} />;
                  })}
              </Tabs>
            </Box>
            <Box display={{xs:"none",sm:"none",md:"flex"}} alignItems={"center"} gap={"30px"}>
              <Typography
                className={classes.download_btn}
                onClick={handleExportAsPDF}
              >
                <DownloadIcon /> Download Report
              </Typography>
              <Link to={"/parent-dashboard"}>
                <Button className={classes.view_more}>Add User</Button>
              </Link>
            </Box>
          </Box>
          {Array.isArray(userInfo?.students) &&
            userInfo?.students?.map((datas, i) => {
              return (
                <CustomTabPanel value={tabValue} index={i} key={datas?._id}>
                  <div ref={printRef}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={12} md={6}>
                        <Box className={classes.my_childrens}>
                          <Grid container>
                            <Grid item xs={12} sm={12} md={12}>
                              <Box
                                display={"flex"}
                                alignItems={"center"}
                                gap={"15px"}
                                height={"100%"}
                              >
                                {datas?.avatarInfo?.image ? (
                                  <img
                                    src={`https://api.meta.apps.org.in/api/${datas?.avatarInfo?.image}`}
                                    width={"100px"}
                                    className={classes.avatar_url}
                                    // onClick={() => navigate("/updateavatar")}
                                  />
                                ) : (
                                  <Typography
                                    className={classes1.split_letters}
                                    // onClick={() => navigate("/updateavatar")}
                                  >
                                    {splitFirstLetter(
                                      `${datas?.first_name} ${datas?.last_name}`
                                    )}
                                  </Typography>
                                )}
                                <Box>
                                  <Typography className={classes.profile_name}>
                                    {datas?.first_name} {datas?.last_name}
                                  </Typography>
                                  <Box
                                    display={"flex"}
                                    gap={"15px"}
                                    position={"relative"}
                                  >
                                    <Typography className={classes.label}>
                                      Age:{" "}
                                      <span className={classes.text}>
                                        {" "}
                                        {datas?.age} Years
                                      </span>{" "}
                                    </Typography>
                                    <Typography
                                      className={`${classes.label} ${classes.before_line}`}
                                    >
                                      Grade:{" "}
                                      <span className={classes.text}>
                                        {datas?.gradeInfo?.grade?.replace(
                                          "_",
                                          " "
                                        ) ||
                                          datas?.grade_name?.replace("_", " ")}
                                      </span>{" "}
                                    </Typography>
                                  </Box>
                                </Box>
                              </Box>
                            </Grid>
                            <Grid item xs={12} sm={12} md={5} mt={3}>
                              <Typography className={classes.myprofile_details}>
                              {userInfo?.studentInfo?.gst_type === "B2B"
                          ? "School"
                          : "Certified By"}
                              </Typography>
                            </Grid>
                            <Grid item xs={12} sm={12} md={7} mt={3}>
                              <Typography className={classes.myprofile_details}>
                                <b>{datas?.schoolInfo?.name}</b>
                              </Typography>
                            </Grid>
                            <Grid item xs={12} sm={12} md={5}>
                              <Typography className={classes.myprofile_details}>
                                {" "}
                                {userInfo?.studentInfo?.gst_type === "B2B"
                          ? "School Email"
                          : "Support Email"}:
                              </Typography>
                            </Grid>
                            <Grid item xs={12} sm={12} md={7}>
                              <Typography className={classes.myprofile_details}>
                                <b> {datas?.schoolInfo?.email}</b>
                              </Typography>
                            </Grid>
                            <Grid item xs={12} sm={12} md={5}>
                              <Typography className={classes.myprofile_details}>
                                {" "}
                                {userInfo?.studentInfo?.gst_type === "B2B"
                          ? "School Phone Number"
                          : "Support Number"}:
                              </Typography>
                            </Grid>
                            <Grid item xs={12} sm={12} md={7}>
                              <Typography className={classes.myprofile_details}>
                                <b> {datas?.schoolInfo?.phone}</b>
                              </Typography>
                            </Grid>
                           {userInfo?.studentInfo?.gst_type === "B2B" &&
                            <>
                            <Grid item xs={12} sm={12} md={5}>
                              <Typography className={classes.myprofile_details}>
                                Class Teacher:
                              </Typography>
                            </Grid>
                            <Grid item xs={12} sm={12} md={7}>
                              <Typography className={classes.myprofile_details}>
                                <b>
                                  {datas?.schoolInfo?.teacher_name ?? "n/a"}
                                </b>
                              </Typography>
                            </Grid>
                           </>}
                          </Grid>
                        </Box>
                      </Grid>
                      <Grid item xs={12} sm={12} md={3}>
                        <Box className={classes1.myprogress} p={3}>
                          <Typography className={classes1.myprogress_title}>
                            Student Progress
                          </Typography>
                          <Box
                            display={"flex"}
                            alignItems={"center"}
                            justifyContent={"space-between"}
                            flexDirection={"column"}
                            gap={"22px"}
                            my={1}
                          >
                            {datas?.avatarInfo?.image ? (
                              <img
                                src={`https://api.meta.apps.org.in/api/${datas?.avatarInfo?.image}`}
                                width={"100px"}
                                className={classes1.avatar_url}
                                // onClick={() => navigate("/updateavatar")}
                              />
                            ) : (
                              <Typography
                                className={classes1.split_letters}
                                // onClick={() => navigate("/updateavatar")}
                              >
                                {splitFirstLetter(
                                  `${datas?.first_name} ${datas?.last_name}`
                                )}
                              </Typography>
                            )}
                            <Box
                              display={"flex"}
                              flexDirection={"column"}
                              gap={"10px"}
                              alignItems={"end"}
                              justifyContent={"space-between"}
                            >
                              <img src={Star} alt="elephant" />
                            </Box>
                          </Box>
                          <Typography
                            className={classes1.progress_level}
                            textAlign={"end"}
                          >
                            Level {datas?.level}
                          </Typography>
                          <Box
                            height={"12px"}
                            bgcolor={"#557CCB"}
                            borderRadius={"20px"}
                            mt={1}
                          >
                            <Typography
                              height={"100%"}
                              width={`${datas?.progress}%`}
                              bgcolor={"#FFCB00"}
                              borderRadius={"20px"}
                            ></Typography>
                          </Box>
                          <Box
                            display={"flex"}
                            justifyContent={"space-between"}
                            alignItems={"center"}
                            mt={1}
                          >
                            <Typography className={classes1.progress_level}>
                              {datas?.progress}% Progress
                            </Typography>
                          </Box>
                        </Box>
                      </Grid>
                      <Grid item xs={12} sm={12} md={3}>
                        <Box className={classes1.mydiamonds}>
                          <Box>
                            <Typography fontSize={"24px"}>Diamonds</Typography>
                            <Typography fontSize={"40px"}>
                              {datas?.diamonds}
                            </Typography>
                          </Box>
                          <img src={Diamond} alt="elephant" className={classes.diamond_img} />
                          <Box>
                            {/* <Link to={"/parent-inventory"}>
                              <Button className={classes1.cardbutton}>
                                Buy Gifts
                              </Button>
                            </Link> */}
                          </Box>
                        </Box>
                      </Grid>
                    </Grid>
                    <Grid container spacing={2} mt={1}>
                      <Grid item xs={6} sm={6} md={3}>
                        <Box className={classes1.completed_game}>
                          <Typography className={classes1.sub_title} mb={2}>
                            Completed Game
                          </Typography>
                          <Box>
                            <Typography>
                              {datas?.completedGames
                                ? datas?.completedGames
                                : 0}
                            </Typography>
                          </Box>
                        </Box>
                      </Grid>
                      <Grid item xs={6} sm={6} md={3}>
                        <Box className={classes1.int_sec}>
                          <Typography className={classes1.sub_title} mb={2}>
                            Interested Section
                          </Typography>
                          <Box
                            className={classes1.coding}
                            bgcolor={
                              userInfo?.studentInfo?.interest.toLowerCase() ===
                                "block_coding" && "#98E9F0"
                            }
                          >
                            <img src={BlockCoding} alt="block-coding" />
                            <Typography mt={1}>Block Coding</Typography>
                          </Box>
                          <Box
                            className={classes1.coding}
                            bgcolor={
                              userInfo?.studentInfo?.interest.toLowerCase() ===
                                "python_coding" && "#98E9F0"
                            }
                            mt={2}
                          >
                            <img src={PythonCoding} alt="block-coding" />
                            <Typography>Python Coding</Typography>
                          </Box>
                        </Box>
                      </Grid>
                      <Grid item xs={12} sm={12} md={6}>
                        <Box className={classes1.myachievments}>
                          <Typography className={classes1.sub_title} mb={2}>
                            My Achievements
                          </Typography>
                          <span>Look below what you have achieved !</span>
                          <Box mt={3} minHeight={"180px"}>
                            {Array.isArray(achievementsList) &&
                            achievementsList?.length > 0 ? (
                              achievementsList
                                ?.slice(0, 2)
                                .map((values, index) => {
                                  return (
                                    <Box className={classes1.single_achiev}>
                                      <img
                                        src={handleAchImg(index)}
                                        alt="achievments"
                                      />
                                      <Box>
                                        <Typography>
                                          {values.topic?.replace("_", " ")}
                                        </Typography>
                                        <span>
                                          Achieved On{" "}
                                          {dayjs(values.createdAt).format(
                                            "MMMM YYYY"
                                          )}
                                        </span>
                                      </Box>
                                    </Box>
                                  );
                                })
                            ) : (
                              <Box
                                display={"flex"}
                                justifyContent={"center"}
                                flexDirection={"column"}
                                alignItems={"center"}
                                height={"100%"}
                              >
                                <img src={Nodata} width={"150px"} />
                                <Typography sx={{ color: "#5D6892" }}>
                                  No Achievements
                                </Typography>
                              </Box>
                            )}
                          </Box>
                          {/* <Link to={"/myachievements"}> */}
                          {achievementsList?.length > 0 && (
                            <Typography
                              className={classes1.bottom_title}
                              onClick={() =>
                                navigate("/myachievements-parent", {
                                  state: { achievementsList },
                                })
                              }
                            >
                              View All
                            </Typography>
                          )}
                          {/* </Link> */}
                          <img
                            src={AchievmentCup}
                            alt="achievments"
                            className={classes1.achievment_cup}
                          />
                        </Box>
                      </Grid>
                    </Grid>
                    <Typography className={classes.sub_topics_header}>
                      Topics <span>(Subscription Level 1)</span>
                    </Typography>
                    <Grid container spacing={2}>
                      {achievments.map((data) => (
                        <Grid item xs={12} sm={12} md={4}>
                          <Box className={classes.sub_topics}>
                            <Box
                              sx={{
                                cursor: "pointer",
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <img src={Topic1} alt="topic" />
                              <span>Computer Programming</span>
                            </Box>
                            <Typography>
                              Lorem ipsum dolor sit amet, consectetur adipi
                              scing elit, sed do eiusmod tempor incididunt ut
                              labore et dolore magna aliqua.
                            </Typography>
                          </Box>
                        </Grid>
                      ))}
                    </Grid>
                  </div>
                </CustomTabPanel>
              );
            })}
        </Container>
      </Box>
    </>
  );
}

export default ParentUser;
