import { Card, Typography, Button, Box } from "@mui/material";
import React, { useState } from "react";
import classes from "./payment.module.css";
import { Col } from "react-bootstrap";
import { ColorsList } from "../../Utilities/commonConstants";
import { GreenCheckedICon } from "../../Assets/Icons/icons";

const SubscriptionCard = ({ index, subscription, handleChoosePack }) => {
  let _role = localStorage.getItem("selected_role");
  return (
    <div key={index} style={{ position: "relative" }}>
      <Card className={classes.subscriptionCardMain}>
        <div
          className={classes.headerContainer}
          style={{ background: ColorsList[index] }}
        >
          <Typography className={classes.cardHeader}>
            {subscription.subscription_name}
          </Typography>
        </div>
        <div className={classes.cardContentContainer}>
          <Typography className={classes.cardPrice}>
            £{subscription?.price} <span>/ {subscription?.duration}</span>
          </Typography>
          <Typography className={`mt-3 ${classes.cardSubHeader}`}>
            What Inclued?
          </Typography>
          <div className={`mt-3 ${classes.featuresContainer } ${classes.feature_height}`}>
            {Array.isArray(subscription?.features)&&
            subscription?.features.map((feature, index) => {
              return (
                <Box className={classes.feature_align}>
                  <span>
                  <GreenCheckedICon />
                  </span>
                  <Typography
                  key={index}
                  className={`mt-2 ${classes.cardSubContent}`}
                >
                  <span>{feature}</span>
                </Typography>
                </Box>
              );
            })}
          </div>
        </div>
      </Card>
      {_role !== "PARENT"&&
            <Button
            className={classes.chooseLevelBtn}
            variant="contained"
            onClick={() => handleChoosePack(subscription)}
          >
            Choose Level
          </Button>
      }

    </div>
  );
};

export default SubscriptionCard;
